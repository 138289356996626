import React from 'react';
import './App.css';
import Root from './components/Root';
import UserContextProvider from './context/UserContext';
import ModalContextProvider from './context/ModalContext';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import WithAxios from './utils/WithAxios'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#002856',
    },
    secondary: {
      main: '#BDBDBD',
    },
  },
});

function App() {
    return (
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <UserContextProvider>
            <ModalContextProvider>
              <WithAxios>
                <div className="container">
                  <Root/>
                </div>
              </WithAxios>
            </ModalContextProvider>
          </UserContextProvider>
        </CookiesProvider>
      </ThemeProvider>
    );
}

export default App;