import React from 'react'
import {CssBaseline, Container, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Documento from './Documento'
import 'fontsource-roboto'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));

export default function Documenti(props) {
    const classes = useStyles()
    const docsData = props.data

    return (
        props.sezione === undefined ? null : <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container spacing={1}>
                    <Grid item sm={12} >
                        <Documento
                            tipo="1"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 1)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="2"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 2)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="3"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 3)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                   </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="4"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 4)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="5"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 5)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="6"
                            sezione={props.sezione}
                            data={docsData && docsData.filter((e) => e.tipo === 6)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}