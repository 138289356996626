import React, {useEffect, useState} from 'react'
import {CssBaseline, Container, Typography, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Elenco from './Elenco'
import Utils from '../../utils/Utils'
import RestService from '../../services/RestService';
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    link: {
      fontWeight: 'bold',
      color: 'mediumBlue'
    }
  }));

export default function Elenchi(props) {
    const classes = useStyles()
    const elenco1 = props.data.filter((e) => e.tipoElenco === 1)
    const elenco2 = props.data.filter((e) => e.tipoElenco === 2)
    const elenco3 = props.data.filter((e) => e.tipoElenco === 3)
    const [corsi, setCorsi] = useState(null)

    useEffect(() => {
      const fetchData = async () => {
        RestService.getCorsi()
          .then((response) => {
              setCorsi(response.data)
          }).catch((e) => {
              console.log(e)
          })
      };
   
      fetchData();
    }, [props]);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography className={classes.formParagraphFirst} variant="h6">
                  Per la compilazione dei corsi Aedes e GL-Aedes, fare riferimento ai seguenti elenchi di corsi
                  <Link 
                      href={process.env.PUBLIC_URL + '/docs/Codici Corsi AEDES.pdf'}
                      target="_blank"
                      className={classes.link}
                  >
                    &nbsp;circolare n.3/2020 PROT.STN U-35-2020 del 23.12.2020
                  </Link>
                  <Link 
                      href={process.env.PUBLIC_URL + '/docs/Codici Corsi NETPRO AEDES.pdf'}
                      target="_blank"
                      className={classes.link}
                  >
                    &nbsp;corsi NETPRO AEDES
                  </Link>
                </Typography>
                {corsi && <><Elenco 
                  data={elenco1.length > 0 ? elenco1[0] : []} 
                  nomeElenco="Elenco Ordinario Valutatori Aedes"
                  tipoElenco="1"
                  titoliIdoneita = {[
                    "Idoneità conseguita in un corso Aedes abilitante dopo il 2 gennaio 2018",
                    "Idoneità conseguita in un corso (Aedes e/o GL-Aedes) abilitante prima del 2 gennaio 2018",
                    "Esperto Aedes"
                  ]}
                  letteraIdoneita2 = "A"
                  refreshData = {props.refreshData}
                  user = {props.user}
                  corsi = {corsi}
                />
                <Elenco 
                  data={elenco2.length > 0  ? elenco2[0] : []} 
                  nomeElenco="Sub Elenco Speciale Valutatori GL-Aedes"
                  tipoElenco="2"
                  titoliIdoneita = {[
                    "Idoneità conseguita in un corso GL-Aedes abilitante dopo il 2 gennaio 2018",
                    "Idoneità conseguita in un corso GL-Aedes abilitante prima del 2 gennaio 2018",
                    "Esperto GL-Aedes"
                  ]}
                  letteraIdoneita2 = "B"
                  refreshData = {props.refreshData}
                  user = {props.user}
                  corsi = {corsi.filter((corso) => corso.tipo === Utils.getNomeElenco(2))}
                />
                <Elenco 
                  data={elenco3.length > 0  ? elenco3[0] : []} 
                  nomeElenco="Sub Elenco Speciale Valutatori BBCC/Chiese e Palazzi"
                  tipoElenco="3"
                  titoliIdoneita = {[
                    "Idoneità conseguita in un corso BBCC/Chiese e Palazzi abilitante dopo l’entrata in vigore delle Indicazioni operative",
                    "Idoneità conseguita in un corso BBCC/Chiese e Palazzi abilitante prima dell’entrata in vigore delle Indicazioni operative",
                    "Esperto BBCC/Chiese e Palazzi"
                  ]}
                  letteraIdoneita2 = "C"
                  refreshData = {props.refreshData}
                  user = {props.user}
                /></>
                }
            </div>
        </Container>
    )
}