import * as Constants from './Constants'

class Utils{
    parseDate(dateString) {
        if (dateString === '' || !dateString){
            return null
        }
        var parts = dateString.split("/");

        return new Date(parseInt(parts[2], 10),
                          parseInt(parts[1], 10) - 1,
                          parseInt(parts[0], 10));
    }

    formatDate(date){
        if (date === undefined){
            return ''
        }

        var month = date.getMonth() + 1
        var day = date.getDate()
        var year = date.getFullYear()

        if (month < 10) 
            month = '0' + month;
        if (day < 10) 
            day = '0' + day;

        return [day, month, year].join('/');
    }

    parseCurrency(currencyString) {
        console.log(currencyString)
        if (currencyString === '' || !currencyString){
            return null
        }
        return currencyString.toString().replace(",",".")
    }

    formatCurrency(currencyString) {
        if (currencyString === undefined || !currencyString){
            return ''
        }
        return currencyString.toString().replace(".",",")
    }

    downloadBlob(blob, fileName){
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
      
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';
      
        document.body.appendChild(link);
      
        link.click();
      
        document.body.removeChild(link);
    };

    getPermessi(livello){
        switch(livello){
            case Constants.USER:
                return 'utente'
            case Constants.SECTION_LEADER:
                return 'caposezione'
            case Constants.ADMIN:
                return 'admin'
            default:
                return 'utente'
        }
    }

    getAbilitazione(abilitato){
        switch(abilitato){
            case 0:
                return 'email non confermata'
            case 1:
                return 'non abilitato'
            case 2:
                return 'abilitato'
            default:
                return 'non abilitato'
        }
    }

    getNomeElenco(tipoElenco){
        switch(Number(tipoElenco)){
            case 1:
                return 'AEDES'
            case 2:
                return 'GL-AEDES'
        }
    }

    getTipoDocumento(family, type){
        if (family === 'user') {
            switch(type){
                case 1:
                    return 'documento di identità'
                case 2:
                    return 'fototessera'
                case 3:
                    return 'codice fiscale'
                case 4:
                    return 'modulo di iscrizione'
                case 5:
                    return 'modulo di iscrizione NTN'
                default:
                    return 'sconosciuto'
            }
        } else if (family === 'sezione') {
            switch(type){
                case 1:
                    return 'documento rappresentante'
                case 2:
                    return 'certificato attribuzione cf'
                case 3:
                    return 'domanda attribuzione EF'
                case 4:
                    return 'richiesta di registrazione'
                case 5:
                    return 'regolamento'
                case 6:
                    return 'condizioni assicurazione'
                default:
                    return 'sconosciuto'
            }
        }
    }

    getProvince(){
        return [
            { name: 'AGRIGENTO', abbreviation: 'AG'},
            { name: 'ALESSANDRIA', abbreviation: 'AL'},
            { name: 'ANCONA', abbreviation: 'AN'},
            { name: 'AOSTA', abbreviation: 'AO'},
            { name: 'AREZZO', abbreviation: 'AR'},
            { name: 'ASCOLI PICENO', abbreviation: 'AP'},
            { name: 'ASTI', abbreviation: 'AT'},
            { name: 'AVELLINO', abbreviation: 'AV'},
            { name: 'BARI', abbreviation: 'BA'},
            { name: 'BARLETTA-ANDRIA-TRANI', abbreviation: 'BAT'},
            { name: 'BELLUNO', abbreviation: 'BL'},
            { name: 'BENEVENTO', abbreviation: 'BN'},
            { name: 'BERGAMO', abbreviation: 'BG'},
            { name: 'BIELLA', abbreviation: 'BI'},
            { name: 'BOLOGNA', abbreviation: 'BO'},
            { name: 'BOLZANO', abbreviation: 'BZ'},
            { name: 'BRESCIA', abbreviation: 'BS'},
            { name: 'BRINDISI', abbreviation: 'BR'},
            { name: 'CAGLIARI', abbreviation: 'CA'},
            { name: 'CALTANISSETTA', abbreviation: 'CL'},
            { name: 'CAMPOBASSO', abbreviation: 'CB'},
            { name: 'CARBONIA-IGLESIAS', abbreviation: 'CI'},
            { name: 'CASERTA', abbreviation: 'CE'},
            { name: 'CATANIA', abbreviation: 'CT'},
            { name: 'CATANZARO', abbreviation: 'CZ'},
            { name: 'CHIETI', abbreviation: 'CH'},
            { name: 'COMO', abbreviation: 'CO'},
            { name: 'COSENZA', abbreviation: 'CS'},
            { name: 'CREMONA', abbreviation: 'CR'},
            { name: 'CROTONE', abbreviation: 'KR'},
            { name: 'CUNEO', abbreviation: 'CN'},
            { name: 'ENNA', abbreviation: 'EN'},
            { name: 'FERMO', abbreviation: 'FM'},
            { name: 'FERRARA', abbreviation: 'FE'},
            { name: 'FIRENZE', abbreviation: 'FI'},
            { name: 'FOGGIA', abbreviation: 'FG'},
            { name: 'FORLI-CESENA', abbreviation: 'FC'},
            { name: 'FROSINONE', abbreviation: 'FR'},
            { name: 'GENOVA', abbreviation: 'GE'},
            { name: 'GORIZIA', abbreviation: 'GO'},
            { name: 'GROSSETO', abbreviation: 'GR'},
            { name: 'IMPERIA', abbreviation: 'IM'},
            { name: 'ISERNIA', abbreviation: 'IS'},
            { name: 'LA SPEZIA', abbreviation: 'SP'},
            { name: 'L\'AQUILA', abbreviation: 'AQ'},
            { name: 'LATINA', abbreviation: 'LT'},
            { name: 'LECCE', abbreviation: 'LE'},
            { name: 'LECCO', abbreviation: 'LC'},
            { name: 'LIVORNO', abbreviation: 'LI'},
            { name: 'LODI', abbreviation: 'LO'},
            { name: 'LUCCA', abbreviation: 'LU'},
            { name: 'MACERATA', abbreviation: 'MC'},
            { name: 'MANTOVA', abbreviation: 'MN'},
            { name: 'MASSA CARRARA', abbreviation: 'MS'},
            { name: 'MATERA', abbreviation: 'MT'},
            { name: 'MEDIO CAMPIDANO', abbreviation: 'VS'},
            { name: 'MESSINA', abbreviation: 'ME'},
            { name: 'MILANO', abbreviation: 'MI'},
            { name: 'MODENA', abbreviation: 'MO'},
            { name: 'MONZA E BRIANZA', abbreviation: 'MB'},
            { name: 'NAPOLI', abbreviation: 'NA'},
            { name: 'NOVARA', abbreviation: 'NO'},
            { name: 'NUORO', abbreviation: 'NU'},
            { name: 'OGLIASTRA', abbreviation: 'OG'},
            { name: 'OLBIA-TEMPIO', abbreviation: 'OT'},
            { name: 'ORISTANO', abbreviation: 'OR'},
            { name: 'PADOVA', abbreviation: 'PD'},
            { name: 'PALERMO', abbreviation: 'PA'},
            { name: 'PARMA', abbreviation: 'PR'},
            { name: 'PAVIA', abbreviation: 'PV'},
            { name: 'PERUGIA', abbreviation: 'PG'},
            { name: 'PESARO URBINO', abbreviation: 'PU'},
            { name: 'PESCARA', abbreviation: 'PE'},
            { name: 'PIACENZA', abbreviation: 'PC'},
            { name: 'PISA', abbreviation: 'PI'},
            { name: 'PISTOIA', abbreviation: 'PT'},
            { name: 'PORDENONE', abbreviation: 'PN'},
            { name: 'POTENZA', abbreviation: 'PZ'},
            { name: 'PRATO', abbreviation: 'PO'},
            { name: 'RAGUSA', abbreviation: 'RG'},
            { name: 'RAVENNA', abbreviation: 'RA'},
            { name: 'REGGIO CALABRIA', abbreviation: 'RC'},
            { name: 'REGGIO EMILIA', abbreviation: 'RE'},
            { name: 'RIETI', abbreviation: 'RI'},
            { name: 'RIMINI', abbreviation: 'RN'},
            { name: 'ROMA', abbreviation: 'RM'},
            { name: 'ROVIGO', abbreviation: 'RO'},
            { name: 'SALERNO', abbreviation: 'SA'},
            { name: 'SASSARI', abbreviation: 'SS'},
            { name: 'SAVONA', abbreviation: 'SV'},
            { name: 'SIENA', abbreviation: 'SI'},
            { name: 'SIRACUSA', abbreviation: 'SR'},
            { name: 'SONDRIO', abbreviation: 'SO'},
            { name: 'TARANTO', abbreviation: 'TA'},
            { name: 'TERAMO', abbreviation: 'TE'},
            { name: 'TERNI', abbreviation: 'TR'},
            { name: 'TORINO', abbreviation: 'TO'},
            { name: 'TRAPANI', abbreviation: 'TP'},
            { name: 'TRENTO', abbreviation: 'TN'},
            { name: 'TREVISO', abbreviation: 'TV'},
            { name: 'TRIESTE', abbreviation: 'TS'},
            { name: 'UDINE', abbreviation: 'UD'},
            { name: 'VARESE', abbreviation: 'VA'},
            { name: 'VENEZIA', abbreviation: 'VE'},
            { name: 'VERBANO-CUSIO-OSSOLA', abbreviation: 'VB'},
            { name: 'VERCELLI', abbreviation: 'VC'},
            { name: 'VERONA', abbreviation: 'VR'},
            { name: 'VIBO VALENTIA', abbreviation: 'VV'},
            { name: 'VICENZA', abbreviation: 'VI'},
            { name: 'VITERBO', abbreviation: 'VT'}
        ]
    }

    getRegioni() {
        return [
            { name: 'Abruzzo'},
            { name: 'Basilicata'},
            { name: 'Calabria'},
            { name: 'Campania'},
            { name: 'Emilia Romagna'},
            { name: 'Friuli Venezia Giulia'},
            { name: 'Lazio'},
            { name: 'Liguria'},
            { name: 'Lombardia'},
            { name: 'Marche'},
            { name: 'Molise'},
            { name: 'Piemonte'},
            { name: 'Puglia'},
            { name: 'Sardegna'},
            { name: 'Sicilia'},
            { name: 'Toscana'},
            { name: 'Trentino-Alto Adige'},
            { name: 'Umbria'},
            { name: 'Valle d\'Aosta'},
            { name: 'Veneto'},
        ]
    }
}

export default new Utils();