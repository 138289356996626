import { useContext } from 'react'
import http from "./http-common";
import {UserContext} from '../context/UserContext'
import AuthenticationService from '../services/AuthenticationService'
import { useHistory } from 'react-router-dom';

const WithAxios = ({ children }) => {
    const user = useContext(UserContext)
    const history = useHistory();

    http.interceptors.response.use(response => response, async (error) => {
        if (401 === error.response.status) {
            AuthenticationService.invalidateSession(user)
            history.push('/')
        }
    })

    return children
}

export default WithAxios