import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import RestService from '../../services/RestService';
import {Tabs, Tab } from '@material-ui/core';
import 'fontsource-roboto';
import DettaglioSezione from './DettaglioSezione'
import Documenti from './Documenti'
import Users from './Users'

export default function Sezione(props) {
    const [sezioneData, setSezioneData] = useState(null)
    const [documentiData, setDocumentiData] = useState(null)
    const isAdmin = props.admin === true

    useEffect(() => {
        const fetchData = async () => {
          if (isAdmin) {
            setDocumentiData(props.data.documenti)
            setSezioneData(props.data)
          } else {
            RestService.getSezione()
                .then((response) => {
                    setDocumentiData(response.data.documenti)
                    setSezioneData(response.data)
                }).catch((e) => {
                    console.log(e)
                })
            }
        };
     
        fetchData();
      }, [props.data]);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addDocumento = (data) => {
        documentiData.push(data)
        setDocumentiData(documentiData)
    }

    const removeDocumento = (id) => {
        const newData = documentiData.filter((e) => e.tipo !== id)
        setDocumentiData(newData)
    }

    return (
        <>
        <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Dettaglio" />
            <Tab label="Documenti" />
            {!isAdmin && <Tab label="Iscritti" />}
        </Tabs>
        {sezioneData &&
            <> 
                <TabPanel value={value} index={0}>
                    <DettaglioSezione 
                        admin={isAdmin} 
                        data={sezioneData} 
                        refreshData={setSezioneData} 
                        reloadData={props.reloadData} 
                        resetSelezionata={props.resetSelezionata}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Documenti admin={isAdmin} data={documentiData} sezione={sezioneData.id} addDocumento={addDocumento} removeDocumento={removeDocumento} />
                </TabPanel>
                {
                    !isAdmin && <TabPanel value={value} index={2}>
                        <Users sezione={sezioneData.id}/>
                    </TabPanel>
                }
            </>
        }
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};