import React, { useContext, useEffect, useState } from 'react'
import RestService from '../../services/RestService';
import {Button, CssBaseline, TextField, Container, Grid, FormHelperText, Radio, FormControlLabel, Typography} from '@material-ui/core';
import {FormLabel, RadioGroup, Checkbox} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import {ModalContext} from '../../context/ModalContext'
import Utils from '../../utils/Utils'
import DateFnsUtils from '@date-io/date-fns'
import {it} from "date-fns/locale"
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    back: {
        margin: theme.spacing(3, 2, 2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function Elenco(props) {
    const modal = useContext(ModalContext)
    const [elencoData, setElencoData] = useState(props.data)
    const tipoElenco = props.tipoElenco
    const nomeElenco = props.nomeElenco
    const titoliIdoneita = props.titoliIdoneita
    const letteraIdoneita2 = props.letteraIdoneita2
    const classes = useStyles()
    const { handleSubmit, control, errors, clearErrors, reset } = useForm({ mode:"onChange" })
    const [tipoIdoneita, setTipoIdoneita] = useState(null)
    const [showIdoneita, setShowIdoneita] = useState(props.data.tipoIdoneita ? [
        props.data.tipoIdoneita.toString()  === '1',
        props.data.tipoIdoneita.toString()  === '2',
        props.data.tipoIdoneita.toString()  === '3'
    ] : [false, false, false])
    const [titoloStessoCorso, setTitoloStessoCorso] = useState(null)
    const [campagnaSopralluogo, setCampagnaSopralluogo] = useState(null)
    const [seminarioRidotto, setSeminarioRidotto] = useState(null)
    const [corsoAggiornamento, setCorsoAggiornamento] = useState(null)
    const [partecipazioneRilievo, setPartecipazioneRilievo] = useState(null)
    const [partecipazioneNormativa, setPartecipazioneNormativa] = useState(null)
    const [dataTitolo, setDatatitolo] = useState(null)
    const [dataSeminario, setDataseminario] = useState(null)
    const [dataCorso, setDatacorso] = useState(null)
    const [corsi, setCorsi] = useState([])
    const [corso, setCorso] = useState(null)
    const [corsoOrganizzatoDa, setCorsoOrganizzatoDa] = useState(null)
    const [altroOrganizzatoDa, setAltroOrganizzatoDa] = useState(null)
    const [sedeCorso, setSedeCorso] = useState(null)

    useEffect(() => {
        setCorsi(props.corsi)
        setElencoData(props.data)
        setTipoIdoneita(props.data.tipoIdoneita ? props.data.tipoIdoneita.toString() : null)
        setShowIdoneita(props.data.tipoIdoneita ? [
            props.data.tipoIdoneita.toString()  === '1',
            props.data.tipoIdoneita.toString()  === '2',
            props.data.tipoIdoneita.toString()  === '3'
        ] : [false, false, false])
        setTitoloStessoCorso(props.data.titoloStessoCorso !== undefined && props.data.titoloStessoCorso !== null ? props.data.titoloStessoCorso.toString() : '')
        setCampagnaSopralluogo(props.data.campagnaSopralluogo !== null ? props.data.campagnaSopralluogo : false)
        setSeminarioRidotto(props.data.seminarioRidotto !== null ? props.data.seminarioRidotto : false)
        setCorsoAggiornamento(props.data.corsoAggiornamento !== null ? props.data.corsoAggiornamento: false)
        setPartecipazioneRilievo(props.data.partecipazioneRilievo !== null ? props.data.partecipazioneRilievo : false)
        setPartecipazioneNormativa(props.data.partecipazioneNormativa !== null ? props.data.partecipazioneNormativa : false)
        setDatatitolo(Utils.parseDate(props.data.dataTitolo))
        setDataseminario(Utils.parseDate(props.data.dataSeminario))
        setDatacorso(Utils.parseDate(props.data.dataCorso))
        setCorso(props.data.corso)
        setCorsoOrganizzatoDa(props.data.corsoOrganizzatoDa)
        setAltroOrganizzatoDa(props.data.altroOrganizzatoDa)
        setSedeCorso(props.data.sedeCorso)
        
        reset({
            tipoElenco: tipoElenco,
            tipoIdoneita: props.data.tipoIdoneita ? props.data.tipoIdoneita.toString() : null,
            corso: props.data.corso,
            corsoOrganizzatoDa: props.data.corsoOrganizzatoDa,
            altroOrganizzatoDa: props.data.altroOrganizzatoDa,
            sedeCorso: props.data.sedeCorso,
            dataTitolo: Utils.parseDate(props.data.dataTitolo),
            titoloStessoCorso: props.data.titoloStessoCorso !== undefined && props.data.titoloStessoCorso !== null ? props.data.titoloStessoCorso.toString() : '',
            altroOrganizzatoDa: props.data.altroOrganizzatoDa,
            sedeAltro: props.data.sedeAltro,
            campagnaSopralluogo: props.data.campagnaSopralluogo !== null ? props.data.campagnaSopralluogo : false,
            eventoSopralluogo: props.data.eventoSopralluogo,
            giorniSopralluogo: props.data.giorniSopralluogo,
            seminarioRidotto: props.data.seminarioRidotto !== null ? props.data.seminarioRidotto : false,
            titoloSeminario: props.data.titoloSeminario,
            dataSeminario: Utils.parseDate(props.data.dataSeminario),
            corsoAggiornamento: props.data.corsoAggiornamento !== null ? props.data.corsoAggiornamento: false,
            titoloCorso: props.data.titoloCorso,
            dataCorso: Utils.parseDate(props.data.dataCorso),
            partecipazioneRilievo: props.data.partecipazioneRilievo !== null ? props.data.partecipazioneRilievo : false,
            eventoRilievo1: props.data.eventoRilievo1,
            eventoRilievo2: props.data.eventoRilievo2,
            eventoRilievo3: props.data.eventoRilievo3,
            giorniRilievo1: props.data.giorniRilievo1,
            giorniRilievo2: props.data.giorniRilievo2,
            giorniRilievo3: props.data.giorniRilievo3,
            partecipazioneNormativa: props.data.partecipazioneNormativa !== null ? props.data.partecipazioneNormativa : false,
            riferimento1: props.data.riferimento1,
            riferimento2: props.data.riferimento2,
            riferimento3: props.data.riferimento3
        })
    }, [props.data])

    const handleTipoIdoneita = (value) => {
        reset({
            corso: null,
            corsoOrganizzatoDa: null,
            altroOrganizzatoDa: null,
            dataTitolo: null,
            sedeCorso: null,
            sedeAltro: null
        })
        setCorso(null)
        setCorsoOrganizzatoDa(null)
        setAltroOrganizzatoDa(null)
        setDatatitolo(null)
        setSedeCorso(null)
        switch (value) {
            case '1':
                setShowIdoneita([true,false,false])
                setCampagnaSopralluogo(false)
                setSeminarioRidotto(false)
                setCorsoAggiornamento(false)
                setPartecipazioneRilievo(false)
                setPartecipazioneNormativa(false)
                break
            case '2':
                setShowIdoneita([false,true,false])
                setPartecipazioneRilievo(false)
                setPartecipazioneNormativa(false)
                break
            case '3':
                setShowIdoneita([false,false,true])
                setCampagnaSopralluogo(false)
                setSeminarioRidotto(false)
                setCorsoAggiornamento(false)
                break
            default:
                setShowIdoneita([false,false,false])
                break
            }
    }

    const resetElenco = () => {
        setElencoData([])
        setDatatitolo(null)
        setDataseminario(null)
        setDatacorso(null)
        setTipoIdoneita(null)
        setShowIdoneita([false, false, false])
        setTitoloStessoCorso(false)
        setCampagnaSopralluogo(false)
        setSeminarioRidotto(false)
        setCorsoAggiornamento(false)
        setPartecipazioneRilievo(false)
        setPartecipazioneNormativa(false)
        setCorso(null)
        setCorsoOrganizzatoDa(null)
        setAltroOrganizzatoDa(null)
        reset()
    }

    const deleteElenco = () => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('tipoElenco', tipoElenco)
        data.append('username', props.user)
        RestService.deleteUserElenco(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Cancellazione')
                if (response.data) {
                    modal.setDialogMessage('Cancellazione effettuata con successo')
                    resetElenco()
                    props.refreshData(response.data)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('username', props.user)
        data.append('tipoElenco', tipoElenco)
        data.append('tipoIdoneita', e.tipoIdoneita)
        corso !== undefined && data.append('corso', corso)
        corsoOrganizzatoDa && data.append('corsoOrganizzatoDa', corsoOrganizzatoDa)
        sedeCorso && data.append('sedeCorso', sedeCorso)
        e.dataTitolo && data.append('dataTitolo', Utils.formatDate(dataTitolo))
        e.titoloStessoCorso !== undefined && data.append('titoloStessoCorso', e.titoloStessoCorso)
        altroOrganizzatoDa && data.append('altroOrganizzatoDa', altroOrganizzatoDa)
        e.sedeAltro && data.append('sedeAltro', e.sedeAltro)
        campagnaSopralluogo !== undefined && data.append('campagnaSopralluogo', campagnaSopralluogo)
        e.eventoSopralluogo && data.append('eventoSopralluogo', e.eventoSopralluogo)
        e.giorniSopralluogo && data.append('giorniSopralluogo', e.giorniSopralluogo)
        seminarioRidotto !== undefined && data.append('seminarioRidotto', seminarioRidotto)
        e.titoloSeminario && data.append('titoloSeminario', e.titoloSeminario)
        e.dataSeminario && data.append('dataSeminario', Utils.formatDate(dataSeminario))
        corsoAggiornamento !== undefined && data.append('corsoAggiornamento', corsoAggiornamento)
        e.titoloCorso && data.append('titoloCorso', e.titoloCorso)
        e.dataCorso && data.append('dataCorso', Utils.formatDate(dataCorso))
        partecipazioneRilievo !== undefined && data.append('partecipazioneRilievo', partecipazioneRilievo)
        e.eventoRilievo1 && data.append('eventoRilievo1', e.eventoRilievo1)
        e.eventoRilievo2 && data.append('eventoRilievo2', e.eventoRilievo2)
        e.eventoRilievo3 && data.append('eventoRilievo3', e.eventoRilievo3)
        e.giorniRilievo1 && data.append('giorniRilievo1', e.giorniRilievo1)
        e.giorniRilievo2 && data.append('giorniRilievo2', e.giorniRilievo2)
        e.giorniRilievo3 && data.append('giorniRilievo3', e.giorniRilievo3)
        partecipazioneNormativa !== undefined && data.append('partecipazioneNormativa', partecipazioneNormativa)
        e.riferimento1 && data.append('riferimento1', e.riferimento1)
        e.riferimento2 && data.append('riferimento2', e.riferimento2)
        e.riferimento3 && data.append('riferimento3', e.riferimento3)
        RestService.updateUserElenco(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data) {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    props.refreshData(response.data)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }

    const onCorsoSelectionChange = (event, value) => {
        if (value) {
            let dataesame = value.dataEsame ? Utils.parseDate(value.dataEsame) : null
            setCorso(value.codice)
            setDatatitolo(dataesame)
            switch(Number(tipoIdoneita)){
                case 1:
                    setCorsoOrganizzatoDa(value.promotore)
                    reset({
                        corso: value.codice,
                        corsoOrganizzatoDa: value.promotore,
                        altroOrganizzatoDa: altroOrganizzatoDa,
                        dataTitolo: dataesame,
                        sedeCorso: sedeCorso
                    })
                    break
                case 2:
                    setAltroOrganizzatoDa(value.promotore)
                    reset({
                        corso: value.codice,
                        corsoOrganizzatoDa: corsoOrganizzatoDa,
                        altroOrganizzatoDa: value.promotore,
                        dataTitolo: dataesame,
                        sedeCorso: sedeCorso
                    })
                    break
            }
        } else {
            setCorso(null)
            setDatatitolo(null)
            switch(Number(tipoIdoneita)){
                case 1:
                    setCorsoOrganizzatoDa(null)
                    reset({
                        corso: null,
                        corsoOrganizzatoDa: null,
                        dataTitolo: null,
                        sedeCorso: null
                    })
                    break
                case 2:
                    setAltroOrganizzatoDa(null)
                    reset({
                        corso: null,
                        altroOrganizzatoDa: null,
                        dataTitolo: null,
                        sedeCorso: null
                    })
                    break
            }
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography><strong>{nomeElenco}</strong></Typography>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form} >
                    <Grid container spacing={1}>
                        <Controller
                            name="tipoIdoneita"
                            control={control}
                            rules={{ 
                                required: "obbligatorio",
                            }}
                            defaultValue={tipoIdoneita}
                            render={({value, onChange}) => (
                                <Grid item sm={12} >
                                    <FormLabel component="legend">Scegliere una delle opzioni seguenti:</FormLabel>
                                    <RadioGroup 
                                        name="tipoIdoneita" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            setTipoIdoneita(e.target.value)
                                            handleTipoIdoneita(e.target.value)
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={titoliIdoneita[0]} />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={titoliIdoneita[1]} />
                                        <FormControlLabel value="3" control={<Radio color="primary"/>} label={titoliIdoneita[2]} />
                                    </RadioGroup>
                                    {errors.tipoIdoneita && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.tipoIdoneita.message}</FormHelperText>}
                                </Grid>
                            )}    
                        />
                        { showIdoneita[0] &&
                            <>
                            { tipoElenco != 3 && 
                                <Grid item sm={3} >
                                    <Autocomplete
                                        options={
                                            corsi.filter((corso) => {
                                                return corso.tipo === Utils.getNomeElenco(tipoElenco)
                                            })
                                        }
                                        getOptionLabel={(option) => option.codice}
                                        onChange={onCorsoSelectionChange}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label="Elenco Corsi"
                                            />
                                        )}
                                    />
                                </Grid>
                            }
                            <Controller
                                name="corso"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={corso || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.corso ? errors.corso.message : null}
                                            label="Corso *"
                                            id="corso"
                                            onChange={(e) => {
                                                onChange(e)
                                                setCorso(e.target.value)
                                                clearErrors('corso')
                                            }}
                                            error={errors.corso !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="corsoOrganizzatoDa"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={elencoData.corsoOrganizzatoDa || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={5} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.corsoOrganizzatoDa ? errors.corsoOrganizzatoDa.message : null}
                                            label="Organizzato da *"
                                            id="corsoOrganizzatoDa"
                                            onChange={(e) => {
                                                onChange(e)
                                                setCorsoOrganizzatoDa(e.target.value) 
                                                clearErrors('corsoOrganizzatoDa')
                                            }}
                                            error={errors.corsoOrganizzatoDa !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="sedeCorso"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={elencoData.sedeCorso || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.sedeCorso ? errors.sedeCorso.message : null}
                                            label="Sede di svolgimento *"
                                            id="sedeCorso"
                                            onChange={(e) => {
                                                onChange(e)
                                                setSedeCorso(e.target.value) 
                                                clearErrors('sedeCorso')
                                            }}
                                            error={errors.sedeCorso !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                <Controller
                                    name="dataTitolo"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={dataTitolo}
                                    render={({value, onChange}) => (
                                        <Grid item sm={5} >
                                            <KeyboardDatePicker
                                                disableFuture
                                                disableToolbar
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="dataTitolo"
                                                label="Data conseguimento titolo *"
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('dataTitolo')
                                                    setDatatitolo(e)
                                                }}
                                                invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                maxDateMessage="Data non valida"
                                            />
                                            {errors.dataTitolo && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataTitolo.message}</FormHelperText>}
                                        </Grid>
                                    )} 
                                />
                            </MuiPickersUtilsProvider>
                            </>
                        }
                        { showIdoneita[1] &&
                            <>
                            <Controller
                                name="corsoOrganizzatoDa"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={elencoData.corsoOrganizzatoDa || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.corsoOrganizzatoDa ? errors.corsoOrganizzatoDa.message : null}
                                            label="Organizzato da *"
                                            id="corsoOrganizzatoDa"
                                            onChange={(e) => {
                                                onChange(e)
                                                setCorsoOrganizzatoDa(e.target.value) 
                                                clearErrors('corsoOrganizzatoDa')
                                            }}
                                            error={errors.corsoOrganizzatoDa !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="sedeCorso"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={elencoData.sedeCorso || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.sedeCorso ? errors.sedeCorso.message : null}
                                            label="Sede di svolgimento *"
                                            id="sedeCorso"
                                            onChange={(e) => {
                                                onChange(e)
                                                setSedeCorso(e.target.value)
                                                clearErrors('sedeCorso')
                                            }}
                                            error={errors.sedeCorso !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="titoloStessoCorso"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={titoloStessoCorso}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <FormLabel component="legend">Conseguimento titolo (esame finale) nell’ambito del medesimo corso:</FormLabel>
                                        <RadioGroup 
                                            row
                                            name="titoloStessoCorso" 
                                            value={value} 
                                            onChange={(e) => {
                                                onChange(e)
                                                setTitoloStessoCorso(e.target.value)
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio color="primary"/>} label="Si" />
                                            <FormControlLabel value="false" control={<Radio color="primary"/>} label="No" />
                                        </RadioGroup>
                                        {errors.titoloStessoCorso && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.titoloStessoCorso.message}</FormHelperText>}
                                    </Grid>
                                )}    
                            />
                            { titoloStessoCorso === 'false' && titoloStessoCorso !== '' &&
                                <>
                                { tipoElenco != 3 && 
                                    <Grid item sm={3} >
                                        <Autocomplete
                                            options={corsi}
                                            getOptionLabel={(option) => option.codice}
                                            onChange={onCorsoSelectionChange}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label="Elenco Corsi"
                                                />
                                            )}
                                        />
                                    </Grid> 
                                }
                                <Controller
                                    name="corso"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={elencoData.corso || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={4} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.corso ? errors.corso.message : null}
                                                label="Corso *"
                                                id="corso"
                                                onChange={(e) => {
                                                    onChange(e)
                                                    setCorso(e.target.value)
                                                    clearErrors('corso')
                                                }}
                                                error={errors.corso !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="altroOrganizzatoDa"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={elencoData.altroOrganizzatoDa || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={5} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.altroOrganizzatoDa ? errors.altroOrganizzatoDa.message : null}
                                                label="Organizzato da *"
                                                id="altroOrganizzatoDa"
                                                onChange={(e) => {
                                                    onChange(e)
                                                    setAltroOrganizzatoDa(e.target.value) 
                                                    clearErrors('altroOrganizzatoDa')
                                                }}
                                                error={errors.altroOrganizzatoDa !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="sedeAltro"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={elencoData.sedeAltro || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.sedeAltro ? errors.sedeAltro.message : null}
                                                label="Sede di svolgimento *"
                                                id="sedeAltro"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('sedeAltro')
                                                }}
                                                error={errors.sedeAltro !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                </>
                            }
                            { titoloStessoCorso !== '' && 
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                    <Controller
                                        name="dataTitolo"
                                        control={control}
                                        rules={{
                                            required: "obbligatorio",
                                        }}
                                        defaultValue={dataTitolo}
                                        render={({value, onChange}) => (
                                            <Grid item sm={5} >
                                                <KeyboardDatePicker
                                                    disableFuture
                                                    disableToolbar
                                                    size="small"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="dataTitolo"
                                                    label="Data conseguimento titolo *"
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e) 
                                                        clearErrors('dataTitolo')
                                                        setDatatitolo(e)
                                                    }}
                                                    invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                    maxDateMessage="Data non valida"
                                                />
                                                {errors.dataTitolo && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataTitolo.message}</FormHelperText>}
                                            </Grid>
                                        )} 
                                    />
                                </MuiPickersUtilsProvider>
                            }
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={campagnaSopralluogo}
                                        onChange={(e) => {
                                            setCampagnaSopralluogo(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                }
                                label= {letteraIdoneita2 + " - Partecipato ad una campagna di sopralluogo negli ultimi 5 anni (2015-2020):"}
                            />
                            { campagnaSopralluogo &&
                                <>
                                <Controller
                                    name="eventoSopralluogo"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.eventoSopralluogo || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.eventoSopralluogo ? errors.eventoSopralluogo.message : null}
                                                label="Evento del *"
                                                id="eventoSopralluogo"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('eventoSopralluogo')
                                                }}
                                                error={errors.eventoSopralluogo !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="giorniSopralluogo"
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: /[1-9]{1}\d*/,
                                            message: "numero non valido"
                                        }
                                    }}
                                    defaultValue={elencoData.giorniSopralluogo || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.giorniSopralluogo ? errors.giorniSopralluogo.message : null}
                                                label="N. giorni *"
                                                id="giorniSopralluogo"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('giorniSopralluogo')
                                                }}
                                                error={errors.giorniSopralluogo !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                </>
                            }
                             <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={seminarioRidotto}
                                        onChange={(e) => {
                                            setSeminarioRidotto(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                }
                                label= {letteraIdoneita2 + "1 - Si è seguito un Seminario ridotto di Aggiornamento:"}
                            />
                            { seminarioRidotto && 
                                <>
                                <Controller
                                    name="titoloSeminario"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={elencoData.titoloSeminario || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.titoloSeminario ? errors.titoloSeminario.message : null}
                                                label="Titolo *"
                                                id="titoloSeminario"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('titoloSeminario')
                                                }}
                                                error={errors.titoloSeminario !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                    <Controller
                                        name="dataSeminario"
                                        control={control}
                                        rules={{
                                            required: "obbligatorio",
                                        }}
                                        defaultValue={dataSeminario}
                                        render={({value, onChange}) => (
                                            <Grid item sm={5} >
                                                <KeyboardDatePicker
                                                    disableFuture
                                                    disableToolbar
                                                    size="small"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="dataSeminario"
                                                    label="Data *"
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e) 
                                                        clearErrors('dataSeminario')
                                                        setDataseminario(e)
                                                    }}
                                                    invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                    maxDateMessage="Data non valida"
                                                />
                                                {errors.dataSeminario && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataSeminario.message}</FormHelperText>}
                                            </Grid>
                                        )} 
                                    />
                                </MuiPickersUtilsProvider>
                                </>
                            }
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={corsoAggiornamento}
                                        onChange={(e) => {
                                            setCorsoAggiornamento(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                }
                                label= {letteraIdoneita2 + "2 - Si è seguito un Corso di Aggiornamento con Esercitazione:"}
                            />
                            { corsoAggiornamento &&
                                <>
                                <Controller
                                    name="titoloCorso"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={elencoData.titoloCorso || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.titoloCorso ? errors.titoloCorso.message : null}
                                                label="Corso *"
                                                id="titoloCorso"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('titoloCorso')
                                                }}
                                                error={errors.titoloCorso !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                    <Controller
                                        name="dataCorso"
                                        control={control}
                                        rules={{
                                            required: "obbligatorio",
                                        }}
                                        defaultValue={dataCorso}
                                        render={({value, onChange}) => (
                                            <Grid item sm={5} >
                                                <KeyboardDatePicker
                                                    disableFuture
                                                    disableToolbar
                                                    size="small"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="dataCorso"
                                                    label="Data *"
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e) 
                                                        clearErrors('dataCorso')
                                                        setDatacorso(e)
                                                    }}
                                                    invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                    maxDateMessage="Data non valida"
                                                />
                                                {errors.dataCorso && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataCorso.message}</FormHelperText>}
                                            </Grid>
                                        )} 
                                    />
                                </MuiPickersUtilsProvider>
                                </>
                            }    
                            <Typography variant="caption" >
                                L’iscrizione è possibile se si è in possesso del requisito {letteraIdoneita2} + {letteraIdoneita2}.1 
                                oppure {letteraIdoneita2}.1 + {letteraIdoneita2}.2 e decorre dalla data di
                                 emanazione delle Indicazioni operative
                            </Typography>
                            </>
                        }
                        { showIdoneita[2] &&
                            <>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                <Controller
                                    name="dataTitolo"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={dataTitolo}
                                    render={({value, onChange}) => (
                                        <Grid item sm={5} >
                                            <KeyboardDatePicker
                                                disableFuture
                                                disableToolbar
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="dataTitolo"
                                                label="Data conseguimento titolo *"
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('dataTitolo')
                                                    setDatatitolo(e)
                                                }}
                                                invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                maxDateMessage="Data non valida"
                                            />
                                            {errors.dataTitolo && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataTitolo.message}</FormHelperText>}
                                        </Grid>
                                    )} 
                                />
                            </MuiPickersUtilsProvider>
                            <FormControlLabel
                                style={{display:'table',  textAlign:'justify'}}
                                control={
                                    <div style={{display:'table-cell'}}>
                                        <Checkbox
                                            checked={partecipazioneRilievo}
                                            onChange={(e) => {
                                                setPartecipazioneRilievo(e.target.checked)
                                            }}
                                            color="primary"
                                        />
                                    </div>
                                }
                                label="partecipazione certificata, alla data di emanazione delle presenti Indicazioni, 
                                        ad attività di rilievo del danno ed agibilità post evento effettuata a partire dal 1997 
                                        per almeno 3 differenti eventi con un numero minimo complessivo di 15 giornate effettive  
                                        di attività oppure un singolo evento con 30 giornate effettive di attività"
                            />
                            { partecipazioneRilievo &&
                                <>
                                <Controller
                                    name="eventoRilievo1"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.eventoRilievo1 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.eventoRilievo1 ? errors.eventoRilievo1.message : null}
                                                label="Evento del"
                                                id="eventoRilievo1"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('eventoRilievo1')
                                                }}
                                                error={errors.eventoRilievo1 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="giorniRilievo1"
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: /[1-9]{1}\d*/,
                                            message: "numero non valido"
                                        }
                                    }}
                                    defaultValue={elencoData.giorniRilievo1 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.giorniRilievo1 ? errors.giorniRilievo1.message : null}
                                                label="N. giorni"
                                                id="giorniRilievo1"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('giorniRilievo1')
                                                }}
                                                error={errors.giorniRilievo1 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="eventoRilievo2"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.eventoRilievo2 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.eventoRilievo2 ? errors.eventoRilievo2.message : null}
                                                label="Evento del"
                                                id="eventoRilievo2"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('eventoRilievo2')
                                                }}
                                                error={errors.eventoRilievo2 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="giorniRilievo2"
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: /[1-9]{1}\d*/,
                                            message: "numero non valido"
                                        }
                                    }}
                                    defaultValue={elencoData.giorniRilievo2 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.giorniRilievo2 ? errors.giorniRilievo2.message : null}
                                                label="N. giorni"
                                                id="giorniRilievo2"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('giorniRilievo2')
                                                }}
                                                error={errors.giorniRilievo2 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="eventoRilievo3"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.eventoRilievo3 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.eventoRilievo3 ? errors.eventoRilievo3.message : null}
                                                label="Evento del"
                                                id="eventoRilievo3"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('eventoRilievo3')
                                                }}
                                                error={errors.eventoRilievo3 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="giorniRilievo3"
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: /[1-9]{1}\d*/,
                                            message: "numero non valido"
                                        }
                                    }}
                                    defaultValue={elencoData.giorniRilievo3 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.giorniRilievo3 ? errors.giorniRilievo3.message : null}
                                                label="N. giorni"
                                                id="giorniRilievo3"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('giorniRilievo3')
                                                }}
                                                error={errors.giorniRilievo3 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                </>
                            }
                            <FormControlLabel
                                style={{display:'table',  textAlign:'justify'}}
                                control={
                                    <div style={{display:'table-cell'}}>
                                        <Checkbox
                                            checked={partecipazioneNormativa}
                                            onChange={(e) => {
                                                setPartecipazioneNormativa(e.target.checked)
                                            }}
                                            color="primary"
                                        />
                                    </div>
                                }
                                label="partecipazione certificata alla predisposizione di atti normativi, direttive e circolari 
                                        inerenti al rilievo del danno e dell’agibilità post sisma, pubblicati ed ufficializzati 
                                        attraverso specifici atti normativi."
                            />
                            { partecipazioneNormativa &&
                                <>
                                <Controller
                                    name="riferimento1"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.riferimento1 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.riferimento1 ? errors.riferimento1.message : null}
                                                label="Riferimento 1"
                                                id="riferimento1"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('riferimento1')
                                                }}
                                                error={errors.riferimento1 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="riferimento2"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.riferimento2 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.riferimento2 ? errors.riferimento2.message : null}
                                                label="Riferimento 2"
                                                id="riferimento2"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('riferimento2')
                                                }}
                                                error={errors.riferimento2 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                <Controller
                                    name="riferimento3"
                                    control={control}
                                    rules={{
                                    }}
                                    defaultValue={elencoData.riferimento3 || ''}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                helperText={errors.riferimento3 ? errors.riferimento3.message : null}
                                                label="Riferimento 3"
                                                id="riferimento3"
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('riferimento3')
                                                }}
                                                error={errors.riferimento3 !== undefined}
                                                value={value || ''}
                                            />
                                        </Grid>
                                    )}   
                                />
                                </>
                            }
                            </>
                        }
                    </Grid>
                    <div style={{textAlign: "right"}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.back}
                            onClick={deleteElenco}
                        >
                            Cancella
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Aggiorna
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}