import React, { useState, useEffect } from 'react'
import RestService from '../../services/RestService';
import {Paper, CssBaseline, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Sezione from '../sezione/Sezione'
import 'fontsource-roboto';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    table: {
      minWidth: 350,
    },
    tablecontainer: {
      marginTop: theme.spacing(4),
    },
    row: {
      cursor: 'pointer',
      "&$selected, &$selected:hover" : {
        backgroundColor: theme.palette.primary.light,
      },
      "&$selected th, &$selected:hover th" : {
        color: theme.palette.common.white,
      }
    },
    selected: {},
    hover:{},
    floatingParent: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    floatingChild: {
        display: 'inline-block',
        padding: '10px'
    }
  }));

export default function Admin() {
    const classes = useStyles()
    const [reloadTable, setReloadTable] = useState(false)
    const [sezioniData, setSezioniData] = useState(null)
    const [selectedSezione, setSelectedSezione] = useState([])

    const resetSelezionata = () => {
        setSelectedSezione([])
    }

    const reloadData = () => {
        setReloadTable(reloadTable ? false : true)
    }

    useEffect(() => {
        const fetchData = async () => {
          RestService.getSezioni()
            .then((response) => {
                setSezioniData(response.data)
            }).catch((e) => {
                console.log(e)
            })
        };
     
        fetchData();
    }, [reloadTable]);


    const selectSezione = (event, id) => {
      const data = sezioniData.filter((e) => e.id === id)[0]
      setSelectedSezione(data)
    };

    return (
        <div className={classes.floatingParent}>
            <div className={`${classes.floatingChild} ${classes.paper}`}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Typography>Lista Sezioni</Typography>
                    {sezioniData &&
                        <TableContainer className={classes.tablecontainer} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nome</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {sezioniData.map((row) => (
                                    <TableRow 
                                        className={classes.row} 
                                        key={row.id}
                                        onClick={
                                            (event) => selectSezione(event, row.id)
                                        }
                                        selected={selectedSezione.id === row.id}
                                        classes={{ selected: classes.selected }}
                                    >
                                        <TableCell
                                            component="th" scope="row"
                                        >
                                            {row.nome}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Container>
            </div>
            <div className={classes.floatingChild}>
                <Sezione 
                    className={classes.floatLeft} 
                    admin={true} 
                    data={selectedSezione} 
                    reloadData={reloadData} 
                    resetSelezionata={resetSelezionata}
                />
            </div>
        </div>
    )
}