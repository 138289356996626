import http from "../utils/http-common";

class RestService {
    
    getUserData(usernameProvided) {
        return http.get("/api/utente?usernameProvided="+usernameProvided)
    }

    getSezioniDropdown() {
        return http.get("/api/sezioniDropdown")
    }

    updateUserAccount(data) {
        return http.post("/api/updateUtente", data)
    }

    updateUserProfile(data) {
        return http.post("/api/updateAnagrafica", data)
    }

    updateUserElenco(data) {
        return http.post("/api/updateElenco", data)
    }

    deleteUserElenco(data) {
        return http.post("/api/deleteElenco", data)
    }

    getAllUsers() {
        return http.get("/api/utenti")
    }

    updateUserEnabled(data){
        return http.post("/api/updateAbilitazione", data)
    }

    getSezioni() {
        return http.get("/api/sezioni")
    }

    getSezione(){
        return http.get("/api/sezione")
    }

    getSezioneUsers(data){
        return http.post("/api/utentiSezione", data)
    }

    updateSezione(data) {
        return http.post("/api/updateSezione", data)
    }

    updateBonifico(data) {
        return http.post("/api/updateBonifico", data)
    }

    deleteBonifico(data) {
        return http.post("/api/deleteBonifico", data)
    }

    getCorsi() {
        return http.get("/api/corsiDropdown")
    }
}

export default new RestService();