import React, { useContext, useState, useEffect } from 'react'
import StorageService from '../../services/StorageService';
import {Button, Grid, Typography} from '@material-ui/core';
import {ModalContext} from '../../context/ModalContext'
import {makeStyles} from '@material-ui/core/styles';
import Utils from '../../utils/Utils'
import {Check, Clear, FormatColorResetSharp} from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    documento:{
        margin: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: red[50],
        padding: theme.spacing(2),
        '&.loaded':{
            backgroundColor: green[50],
        }
    },
    docButton: {
        marginRight: theme.spacing(2),
    }
  }));

export default function Documento(props) {
    const modal = useContext(ModalContext)
    const classes = useStyles()
    const [data, setData] = useState(props.data)
    const [loaded, setLoaded] = useState(data)
    const [selectedFile, setSelectedFile] = useState(null)
    const typeDecoded = Utils.getTipoDocumento('user', Number(props.tipo))
    const documentClasses = loaded ? `${classes.documento} loaded` : `${classes.documento}`
    const [link, setLink] = useState(null)
    const [ratioOk, setRatioOk] = useState(true)

    useEffect(() => {
        const updateData = (data) => {
            setData(data)
            setLoaded(data !== null && data !== undefined)
        }

        const fetchData = async () => {
            updateData(props.data)
        }

        fetchData()
    }, [props.data])

    const open = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Apertura')
        let formData = new FormData()
        formData.append("username", data.username)
        formData.append("tipo", data.tipo)

        StorageService.openUserDoc(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data.message === 'ok') {
                    setLink(response.data.link)
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Problema nell\'apertura del file')
                modal.toggleDialog()
            });
    }

    const deleteFile = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Cancellazione')
        let formData = new FormData()
        formData.append("username", data.username)
        formData.append("tipo", data.tipo)
    
        StorageService.deleteUserDoc(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data.message === 'ok') {
                    modal.setDialogMessage('Cancellazione effettuata con successo')
                    props.removeDocumento(data.tipo)
                    setLoaded(false)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Cancellazione del file fallita')
                modal.toggleDialog()
            });
    }

    const upload = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Upload')
        let formData = new FormData()
        formData.append("documento", selectedFile)
        formData.append("username", props.user)
        formData.append("tipo", props.tipo)
    
        StorageService.uploadUserDoc(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data) {
                    modal.setDialogMessage('Upload effettuato con successo')
                    props.addDocumento(response.data)
                    setData(response.data)
                    setLoaded(true)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Upload del file fallito')
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Upload del file fallito')
                modal.toggleDialog()
                setSelectedFile(null)
            });

        setSelectedFile(null)
    }

    const fileSelectionHandler = (e) => {
        setSelectedFile(e.target.files[0])
        if (props.tipo == 2) { // fototessera
            var img = new Image()
            img.onload = function () {
                var ratio = this.width/this.height
                if (ratio < 0.71 || ratio > 0.78) {
                    modal.setDialogMessage('Dimensioni della fototessera non conformi, utilizzare formati 3.5x4.5 o 5x7')
                    modal.toggleDialog()
                    setSelectedFile(null)
                    setRatioOk(false)
                } else {
                    setRatioOk(true)
                }
            }
            img.src = window.URL.createObjectURL(e.target.files[0])
        }
    }
    
    return (
        <div className={documentClasses}>
            <Grid container spacing={1}>
                <Grid item sm={1} >
                    {loaded && 
                        <Check
                            htmlColor='green'
                            fontSize='large'
                        />
                    }
                    {!loaded && 
                        <Clear
                            htmlColor='red'
                            fontSize='large'
                        />
                    }
                </Grid>
                <Grid item sm={5}>
                    <Typography style={{marginTop:'5px'}}>{typeDecoded}</Typography>
                </Grid>
                <Grid item sm={6} >
                    { loaded && 
                        <Button
                            className={classes.docButton}
                            variant="outlined"
                            color="primary"
                            component="span" 
                            onClick={deleteFile}   
                        >
                            Cancella file
                        </Button>
                    }
                    { !loaded &&
                        <label htmlFor={`btn-upload-${props.tipo}`} >   
                            <input
                                id={`btn-upload-${props.tipo}`}
                                name={`btn-upload-${props.tipo}`}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={fileSelectionHandler} 
                            />
                            <Button
                                className={classes.docButton}
                                variant="contained"
                                color="primary"
                                component="span" >
                                Seleziona File
                            </Button>
                        </label>
                    }
                    { !loaded && selectedFile && selectedFile.size > 0 &&
                        <Button
                            className={classes.docButton}
                            variant="contained"
                            color="primary"
                            component="span" 
                            onClick={upload} 
                        >
                            Upload
                        </Button>              
                    }
                    { !loaded &&selectedFile && selectedFile.size > 0 && 
                        <>
                            <br/>
                            <Typography variant="caption">
                                {selectedFile.name}
                            </Typography>
                        </>
                    }
                    { loaded &&
                        <Button
                            className={classes.docButton}
                            variant="contained"
                            color="primary"
                            component="span" 
                            onClick={open}    
                    >
                        Crea Link
                        </Button>
                    }
                    { loaded && link &&
                        <Button
                            className={classes.docButton}
                            variant="contained"
                            color="primary"
                            component="span" 
                            onClick={() => window.open(link, '_blank')}  
                        >
                        Apri
                        </Button>
                    }
                </Grid>
            </Grid>
        </div>
    )
}