import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {Tabs, Tab } from '@material-ui/core'
import 'fontsource-roboto'
import ManageUsers from './ManageUsers'
import Sezioni from './Sezioni'

export default function Admin() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <>
        <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Utenti" />
            <Tab label="Sezioni" />
        </Tabs> 
        <TabPanel value={value} index={0}>
            <ManageUsers />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Sezioni />
        </TabPanel>
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};