import React, { useContext } from 'react';
import {Backdrop, CircularProgress, Dialog, DialogContentText, DialogContent, DialogActions, DialogTitle} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ModalContext} from '../context/ModalContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}));

export default function Modal(){
    const modal = useContext(ModalContext)
    const history = useHistory();
    const classes = useStyles()

    const handleCloseDialog = () => {
        modal.toggleDialog()
        if (modal.returnHome) {
            modal.setReturnHome(false)
            history.push('/')
        }
    }

    return (
        <>
            <Backdrop className={classes.backdrop} open={modal.showBackdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={modal.showDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle id="alert-dialog-title">{modal.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {modal.dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}