import {React, useState, useEffect, useContext } from 'react'
import {CssBaseline, Container, Grid, Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Documento from './Documento'
import {ModalContext} from '../../context/ModalContext'
import {UserContext} from '../../context/UserContext'
import DocService from '../../services/DocService';
import Utils from '../../utils/Utils'
import 'fontsource-roboto'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));

export default function Documenti(props) {
    const modal = useContext(ModalContext)
    const user = useContext(UserContext)
    const classes = useStyles()
    const docsData = props.data
    const [checkModuloIscrizione, setCheckModuloIscrizione] = useState(null)
    const [okModuloIscrizione, setOkModuloIscrizione] = useState(null)
    const [checkModuloIscrizioneNTN, setCheckModuloIscrizioneNTN] = useState(null)
    const [okModuloIscrizioneNTN, setOkModuloIscrizioneNTN] = useState(null)

    const openModuloIscrizione = () => {
        modal.toggleBackdrop()
        DocService.getModuloIscrizione(props.user)
            .then((response) => {
                const blob = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});
                    Utils.downloadBlob(blob, 'modulo-iscrizione.pdf')
                modal.toggleBackdrop()
            }).catch(() => {
                modal.toggleBackdrop()
            })
    }

    const openModuloIscrizioneNTN = () => {
        modal.toggleBackdrop()
        DocService.getModuloIscrizioneNTN(props.user)
            .then((response) => {
                const blob = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});
                    Utils.downloadBlob(blob, 'modulo-iscrizione-NTN.pdf')
                modal.toggleBackdrop()
            }).catch(() => {
                modal.toggleBackdrop()
            })
    }

    useEffect(() => {
        const fetchData = async () => {
            DocService.checkModuloIscrizione(props.user)
            .then((response) => {
                setCheckModuloIscrizione(response.data.message)
                setOkModuloIscrizione(response.data.message === 'ok')
            }).catch((e) => {
                console.log(e)
            })
            DocService.checkModuloIscrizioneNTN(props.user)
            .then((response) => {
                setCheckModuloIscrizioneNTN(response.data.message)
                setOkModuloIscrizioneNTN(response.data.message === 'ok')
            }).catch((e) => {
                console.log(e)
            })
        };

        fetchData();
    }, [props.data]);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container spacing={1}>
                    <Grid item sm={12} style={{textAlign: 'center'}}>
                        <Button 
                            style={{marginTop: 20}} 
                            onClick = {openModuloIscrizione}
                            variant="contained" 
                            color="primary"
                            disabled={okModuloIscrizione ? false : true }
                        >
                            Genera modulo di Iscrizione
                        </Button>
                        <Typography>
                            {okModuloIscrizione ? 'Il file va scaricato, firmato a mano e digitalmente e caricato nel campo seguente' : checkModuloIscrizione}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="4"
                            user={props.user}
                            data={docsData && docsData.filter((e) => e.tipo === 4)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} style={{textAlign: 'center'}}>
                        <Button 
                            style={{marginTop: 20}} 
                            onClick = {openModuloIscrizioneNTN}
                            variant="contained" 
                            color="primary"
                            disabled={okModuloIscrizioneNTN ? false : true }
                        >
                            Genera modulo di Iscrizione NTN
                        </Button>
                        <Typography>
                            {okModuloIscrizioneNTN ? 'Il file va scaricato, firmato a mano e digitalmente e caricato nel campo seguente' : checkModuloIscrizioneNTN}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="5"
                            user={props.user}
                            data={docsData && docsData.filter((e) => e.tipo === 5)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="1"
                            user={props.user}
                            data={docsData && docsData.filter((e) => e.tipo === 1)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="2"
                            user={props.user}
                            data={docsData && docsData.filter((e) => e.tipo === 2)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Documento
                            tipo="3"
                            user={props.user}
                            data={docsData && docsData.filter((e) => e.tipo === 3)[0]}
                            addDocumento={props.addDocumento} removeDocumento={props.removeDocumento}
                        />
                   </Grid>
                </Grid>
            </div>
        </Container>
    )
}