import React, { useContext } from 'react'
import { withRouter, useHistory, NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationService from '../services/AuthenticationService';
import { UserContext } from '../context/UserContext'
import { AppBar, Toolbar, Button, IconButton } from '@material-ui/core';
import { AccountCircle }  from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    brandWrapper: {
        flexGrow: 1
    },
    brand: {
        fontSize: 28,
        color: 'white',
        marginRight: theme.spacing(4),
    },
    headerText: {
        color: 'white',
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&.active':{
            borderBottom: '1px solid white',
        }
    },
    headerButton: {
        color: 'white',
        textTransform: 'uppercase',
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        paddingRight: '12px',
        '& button':{
            color: 'white',
        },
        '&.active':{
            borderBottom: '1px solid white',
        }
    },
  }));

function Header() {
    const user = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();
    const goToPath = (path) => {
        history.push(path)
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <div className={classes.brandWrapper}>
                    <Button className={classes.brand} onClick={()=>goToPath('/')}>NET.PRO</Button>
                    {user.isAdmin() && <NavLink className={classes.headerText} to="/admin">Amministrazione</NavLink>}
                    {(user.isSectionLeader() || user.isAdmin()) 
                        && <NavLink className={classes.headerText} to="/sezione">Sezione</NavLink>}
                </div>
                <Button className={classes.headerButton} onClick={()=>AuthenticationService.logout(user)}>Logout</Button>
                {user.enabled > 1 && 
                    <NavLink className={classes.headerIcon} to="/user">
                        <IconButton edge="end">
                            <AccountCircle />
                        </IconButton>
                    </NavLink>
                }
            </Toolbar>
        </AppBar>
    )
}

export default withRouter(Header)