import http from "../utils/http-common";

class StorageService {

    uploadUserDoc(data) {
        return http.post("/storage/uploadUserDoc", data)
    }

    deleteUserDoc(data) {
        return http.post("/storage/deleteUserDoc", data)
    }

    openUserDoc(data) {
        return http.post("/storage/openUserDoc", data)       
    }

    uploadSezioneDoc(data) {
      return http.post("/storage/uploadSezioneDoc", data)
    }

    deleteSezioneDoc(data) {
        return http.post("/storage/deleteSezioneDoc", data)
    }

    openSezioneDoc(data) {
        return http.post("/storage/openSezioneDoc", data)       
    }

    uploadRicevutaBonifico(data) {
        return http.post("/storage/uploadRicevutaBonifico", data)
    }

    deleteRicevutaBonifico(data) {
        return http.post("/storage/deleteRicevutaBonifico", data)
    }

    openRicevutaBonifico(data) {
        return http.post("/storage/openRicevutaBonifico", data)       
    }
 
    uploadSezioneLogo(data) {
        return http.post("/storage/uploadSezioneLogo", data)
    }

    deleteSezioneLogo(data) {
        return http.post("/storage/deleteSezioneLogo", data)
    }

    openSezioneLogo(data) {
        return http.post("/storage/openSezioneLogo", data, {responseType: 'blob'})
    }
}
    
export default new StorageService();