import React, { useContext, useState, useEffect } from 'react'
import RestService from '../../services/RestService'
import {Button, CssBaseline, TextField, Container, Grid, InputLabel, Select, MenuItem, FormHelperText, Radio, FormControlLabel} from '@material-ui/core'
import {FormLabel, RadioGroup} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import {ModalContext} from '../../context/ModalContext'
import Utils from '../../utils/Utils'
import FormValidation from '../../utils/FormValidation'
import DateFnsUtils from '@date-io/date-fns'
import {it} from "date-fns/locale"
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import 'fontsource-roboto'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function Profile(props) {
    const modal = useContext(ModalContext)
    const profileData = props.data
    const classes = useStyles()
    const [dataNascita, setDatanascita] = useState(null)
    const [titoloStudio, setTitoloStudio] = useState(null)
    const [dipendentePA, setDipendentePA] = useState(null)
    const [opzioneIscrizione, setOpzioneIscrizione] = useState(null)
    const [showAmministrazione, setShowAmministrazione] = useState(null)
    const [showOrdine, setShowOrdine] = useState(null)
    const [tipoOrdine, setTipoOrdine] = useState(null)
    const [ordine, setOrdine] = useState(null)
    const [sezioneOrdine, setSezioneOrdine] = useState(null)
    const [showProfessione, setShowProfessione] = useState(null)
    const [showCertificazione, setShowCertificazione] = useState(null)
    const province = Utils.getProvince()
    const [certPAData, setCertPAData] = useState(null)
    const [provinciaResidenza, setProvinciaResidenza] = useState(null)
    const [provinciaNascita, setProvinciaNascita] = useState(null)
    const [firmaDigitale, setFirmaDigitale] = useState(null)

    useEffect(() => {
        setDatanascita(Utils.parseDate(props.data.dataNascita))
        setTitoloStudio(props.data.titoloStudio ? props.data.titoloStudio : '')
        setDipendentePA(props.data.dipendentePA !== null ? props.data.dipendentePA.toString() : '')
        setOpzioneIscrizione(props.data.opzioneIscrizione !== null ? props.data.opzioneIscrizione.toString() : undefined)
        setShowAmministrazione(props.data.dipendentePA)
        setShowOrdine(props.data.opzioneIscrizione !== null ? props.data.opzioneIscrizione.toString()  === '1' : false)
        setTipoOrdine(props.data.tipoOrdine ? props.data.tipoOrdine : '')
        setOrdine(props.data.ordine ? props.data.ordine : '')
        setSezioneOrdine(props.data.sezioneOrdine ? props.data.sezioneOrdine : '')
        setShowProfessione(props.data.opzioneIscrizione !== null ? props.data.opzioneIscrizione.toString()  === '2' : false)
        setShowCertificazione(props.data.opzioneIscrizione !== null ? props.data.opzioneIscrizione.toString()  === '3' : false)
        setCertPAData(Utils.parseDate(props.data.certPAData))
        setProvinciaResidenza(props.data.provinciaResidenza ? props.data.provinciaResidenza : '')
        setProvinciaNascita(props.data.provinciaNascita ? props.data.provinciaNascita : '')
        setFirmaDigitale(props.data.firmaDigitale ? props.data.firmaDigitale : '')
        reset({
            luogoNascita: props.data.luogoNascita,
            provinciaNascita: props.data.provinciaNascita ? props.data.provinciaNascita : '',
            dataNascita: Utils.parseDate(props.data.dataNascita),
            toponimoResidenza: props.data.toponimoResidenza,
            indirizzoResidenza: props.data.indirizzoResidenza,
            comuneResidenza: props.data.comuneResidenza,
            provinciaResidenza: props.data.provinciaResidenza ? props.data.provinciaResidenza : '',
            capResidenza: props.data.capResidenza,
            cellulare1: props.data.cellulare1,
            cellulare2: props.data.cellulare2,
            telefono1: props.data.telefono1,
            telefono2: props.data.telefono2,
            pec: props.data.pec,
            specializzazione1: props.data.specializzazione1,
            specializzazione2: props.data.specializzazione2,
            specializzazione3: props.data.specializzazione3,
            firmaDigitale: props.data.firmaDigitale ? props.data.firmaDigitale : '',
            firmaDigitaleAltro: props.data.firmaDigitaleAltro,
            titoloStudio: props.data.titoloStudio ? props.data.titoloStudio : '',
            dipendentePA: props.data.dipendentePA !== null ? props.data.dipendentePA.toString() : '',
            amministrazione: props.data.amministrazione,
            opzioneIscrizione: props.data.opzioneIscrizione !== null ? props.data.opzioneIscrizione.toString() : undefined,
            tipoOrdine: props.data.tipoOrdine ? props.data.tipoOrdine : '',
            ordine: props.data.ordine ? props.data.ordine : '',
            numeroOrdine: props.data.numeroOrdine,
            sezioneOrdine: props.data.sezioneOrdine ? props.data.sezioneOrdine : '',
            abilitazioneProfessione: props.data.abilitazioneProfessione,
            certPARilasciataDa: props.data.certPARilasciataDa,
            certPAData: Utils.parseDate(props.data.certPAData),
            certPAProtocollo: props.data.certPAProtocollo,
        })
        clearErrors()
    }, [props.data])

    const handleIscrizione = (value) => {
        switch (value) {
            case '1':
                setShowOrdine(true)
                setShowProfessione(false)
                setShowCertificazione(false)
                break
            case '2':
                setShowOrdine(false)
                setShowProfessione(true)
                setShowCertificazione(false)
                break
            case '3':
                setShowOrdine(false)
                setShowProfessione(false)
                setShowCertificazione(true)
                break
            default:
                setShowOrdine(false)
                setShowProfessione(false)
                setShowCertificazione(false)
                break
            }
    }

    const { handleSubmit, control, errors, clearErrors, reset, getValues } = useForm({
    })

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('username', profileData.username)
        data.append('luogoNascita', e.luogoNascita)
        data.append('provinciaNascita', provinciaNascita)
        data.append('dataNascita', Utils.formatDate(dataNascita))
        data.append('toponimoResidenza', e.toponimoResidenza)
        data.append('indirizzoResidenza', e.indirizzoResidenza)
        data.append('civicoResidenza', e.civicoResidenza)
        data.append('comuneResidenza', e.comuneResidenza)
        data.append('provinciaResidenza', provinciaResidenza)
        data.append('capResidenza', e.capResidenza)
        data.append('cellulare1', e.cellulare1)
        e.cellulare2 && data.append('cellulare2', e.cellulare2)
        data.append('telefono1', e.telefono1)
        e.telefono2 && data.append('telefono2', e.telefono2)
        data.append('pec', e.pec)
        e.specializzazione1 && data.append('specializzazione1', e.specializzazione1)
        e.specializzazione2 && data.append('specializzazione2', e.specializzazione2)
        e.specializzazione3 && data.append('specializzazione3', e.specializzazione3)
        firmaDigitale && data.append('firmaDigitale', firmaDigitale)
        e.firmaDigitaleAltro && data.append('firmaDigitaleAltro', e.firmaDigitaleAltro)
        data.append('titoloStudio', titoloStudio)
        data.append('dipendentePA', e.dipendentePA)
        showAmministrazione && e.amministrazione && data.append('amministrazione', e.amministrazione)
        data.append('opzioneIscrizione', opzioneIscrizione)
        if (showOrdine) {
            tipoOrdine && data.append('tipoOrdine', tipoOrdine)
            ordine && data.append('ordine', ordine)
            e.numeroOrdine && data.append('numeroOrdine', e.numeroOrdine)
            sezioneOrdine && data.append('sezioneOrdine', sezioneOrdine)
        }
        showProfessione && e.abilitazioneProfessione && data.append('abilitazioneProfessione', e.abilitazioneProfessione)
        if (showCertificazione) {
            e.certPARilasciataDa && data.append('certPARilasciataDa', e.certPARilasciataDa)
            certPAData && data.append('certPAData', Utils.formatDate(certPAData))
            e.certPAProtocollo && data.append('certPAProtocollo', e.certPAProtocollo)
        }
        RestService.updateUserProfile(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data) {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    props.refreshData(response.data)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form} >
                    <Grid container spacing={1}>
                        <Controller
                            name="luogoNascita"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.luogoNascita || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={4} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.luogoNascita ? errors.luogoNascita.message : null}
                                        label="Luogo di Nascita *"
                                        id="luogoNascita"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('luogoNascita')
                                        }}
                                        error={errors.luogoNascita !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}   
                        />
                        <Grid item sm={4} >
                            <Controller
                                name="provinciaNascita"
                                id="provinciaNascita"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={provinciaNascita || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="provinciaN-label">Provincia di nascita *</InputLabel>
                                    <Select
                                        labelId="provinciaN-label"
                                        fullWidth
                                        error={errors.provinciaNascita !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setProvinciaNascita(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        {
                                            province.map( prov =>
                                                <MenuItem key={prov.abbreviation} value={prov.name}>{prov.name}</MenuItem>
                                            )
                                        } 
                                    </Select>
                                    </>    
                                )}    
                            />
                            {errors.provinciaNascita && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.provinciaNascita.message}</FormHelperText>}
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                            <Controller
                                name="dataNascita"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={dataNascita}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <KeyboardDatePicker
                                            disableFuture
                                            disableToolbar
                                            size="small"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="dataNascita"
                                            label="Data di nascita *"
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('dataNascita')
                                                setDatanascita(e)
                                            }}
                                            invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                            maxDateMessage="Data non valida"
                                        />
                                        {errors.dataNascita && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataNascita.message}</FormHelperText>}
                                    </Grid>
                                )} 
                            />
                        </MuiPickersUtilsProvider>
                        <Controller
                            name="toponimoResidenza"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.toponimoResidenza || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={3} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.toponimoResidenza ? errors.toponimoResidenza.message : null}
                                        label="Toponimo *"
                                        id="toponimoResidenza"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('toponimoResidenza')
                                        }}
                                        error={errors.toponimoResidenza !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )} 
                        />
                        <Controller
                            name="indirizzoResidenza"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.indirizzoResidenza || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={7} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.indirizzoResidenza ? errors.indirizzoResidenza.message : null}
                                        label="Indirizzo *"
                                        id="indirizzoResidenza"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('indirizzoResidenza')
                                        }}
                                        error={errors.indirizzoResidenza !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )} 
                        />
                        <Controller
                            name="civicoResidenza"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.civicoResidenza || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={2} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.civicoResidenza ? errors.civicoResidenza.message : null}
                                        label="Civico nr. *"
                                        id="civicoResidenza"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('civicoResidenza')
                                        }}
                                        error={errors.civicoResidenza !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )} 
                        />
                        <Controller
                            name="comuneResidenza"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.comuneResidenza || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.comuneResidenza ? errors.comuneResidenza.message : null}
                                        label="Comune *"
                                        id="comuneResidenza"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('comuneResidenza')
                                        }}
                                        error={errors.comuneResidenza !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )} 
                        />
                        <Grid item sm={4} >
                            <Controller
                                name="provinciaResidenza"
                                id="provinciaResidenza"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={provinciaResidenza || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="provincia-label">Provincia *</InputLabel>
                                    <Select
                                        labelId="provincia-label"
                                        fullWidth
                                        error={errors.provinciaResidenza !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setProvinciaResidenza(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        {
                                            province.map( prov =>
                                                <MenuItem key={prov.abbreviation} value={prov.name}>{prov.name}</MenuItem>
                                            )
                                        } 
                                    </Select>
                                    </>    
                                )}    
                            />
                            {errors.provinciaResidenza && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.provinciaResidenza.message}</FormHelperText>}
                        </Grid>
                        <Controller
                            name="capResidenza"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                                minLength: 5,
                                maxLength: 5,
                                pattern: {
                                    value: /[0-9]{5}/,
                                }
                            }}
                            defaultValue={profileData.capResidenza || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={2} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.capResidenza ? errors.capResidenza.message : null}
                                        label="CAP *"
                                        id="capResidenza"
                                        name="capResidenza"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('capResidenza')
                                        }}
                                        error={errors.capResidenza !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )} 
                        />
                         <Controller
                            name="cellulare1"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.cellulare1 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.cellulare1 ? errors.cellulare1.message : null}
                                        label="Cellulare Principale *"
                                        id="cellulare1"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('cellulare1')
                                        }}
                                        error={errors.cellulare1 !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}  
                        />
                        <Controller
                            name="cellulare2"
                            control={control}
                            rules={{ }}
                            defaultValue={profileData.cellulare2 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.cellulare2 ? errors.cellulare2.message : null}
                                        label="Cellulare Alternativo"
                                        id="cellulare2"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('cellulare2')
                                        }}
                                        error={errors.cellulare2 !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="telefono1"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={profileData.telefono1 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.telefono1 ? errors.telefono1.message : null}
                                        label="Telefono Principale *"
                                        id="telefono1"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('telefono1')
                                        }}
                                        error={errors.telefono1 !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="telefono2"
                            control={control}
                            rules={{ }}
                            defaultValue={profileData.telefono2 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.telefono2 ? errors.telefono2.message : null}
                                        label="Telefono Alternativo"
                                        id="telefono2"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('telefono2')
                                        }}
                                        error={errors.telefono2 !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="pec"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "indirizzo pec non valido"
                                }
                            }}
                            defaultValue={profileData.pec || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.pec ? errors.pec.message : null}
                                        label="PEC *"
                                        id="pec"
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('pec')
                                        }}
                                        error={errors.pec !== undefined}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Grid item sm={6} >
                            <Controller
                                name="titoloStudio"
                                id="titoloStudio"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={titoloStudio}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="titoloStudio-label">Titolo di Studio *</InputLabel>
                                    <Select
                                        labelId="titoloStudio-label"
                                        fullWidth
                                        error={errors.titoloStudio !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setTitoloStudio(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        <MenuItem key={1} value={1}>Ingegneria Edile</MenuItem>
                                        <MenuItem key={2} value={2}>Laurea Architettura</MenuItem>
                                        <MenuItem key={3} value={3}>Geologia</MenuItem>
                                        <MenuItem key={4} value={4}>Geometra</MenuItem>
                                        <MenuItem key={5} value={5}>Ingegneria Edile-Architettura</MenuItem>
                                        <MenuItem key={6} value={6}>Ingegneria Ambiente e Territorio</MenuItem>
                                        <MenuItem key={7} value={7}>Laurea Ingegneria</MenuItem>
                                        <MenuItem key={8} value={8}>Laurea Scienze Geologiche</MenuItem>
                                        <MenuItem key={9} value={9}>Perito Edile</MenuItem>
                                        <MenuItem key={10} value={10}>Perito Industriale</MenuItem>
                                        <MenuItem key={11} value={11}>Perito Informatico</MenuItem>
                                        <MenuItem key={12} value={12}>Diploma</MenuItem>
                                        <MenuItem key={13} value={13}>Perito Agrario</MenuItem>
                                        <MenuItem key={14} value={14}>Licenza Media</MenuItem>
                                        <MenuItem key={15} value={15}>Laurea Ingegneria Civile</MenuItem>
                                        <MenuItem key={16} value={16}>Laurea in Ingegneria</MenuItem>
                                        <MenuItem key={17} value={17}>Laurea Triennale Ingegneria</MenuItem>
                                        <MenuItem key={18} value={18}>Geometra Laurea</MenuItem>
                                        <MenuItem key={19} value={19}>Laurea Magistrale in Geologia</MenuItem>
                                        <MenuItem key={20} value={20}>Non Disponibile</MenuItem>
                                    </Select>
                                    </>    
                                )}    
                            />
                            {errors.titoloStudio && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.titoloStudio.message}</FormHelperText>}
                        </Grid>
                        <Controller
                            name="specializzazione1"
                            control={control}
                            rules={{ }}
                            defaultValue={profileData.specializzazione1 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.specializzazione1 ? errors.specializzazione1.message : null}
                                        label="Altre specializzazioni 1"
                                        id="specializzazione1"
                                        error={errors.specializzazione1 !== undefined}
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('specializzazione1')
                                        }}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="specializzazione2"
                            control={control}
                            rules={{ 
                                validate: () => FormValidation.checkSpecializzazioni(
                                        getValues("specializzazione1"), getValues("specializzazione2"), getValues("specializzazione3"),
                                    )
                            }}
                            defaultValue={profileData.specializzazione2 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.specializzazione2 ? "compilare le specializzazioni in ordine" : null}
                                        label="Altre specializzazioni 2"
                                        id="specializzazione2"
                                        error={errors.specializzazione2 !== undefined}
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('specializzazione2')
                                        }}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="specializzazione3"
                            control={control}
                            rules={{ 
                                validate: () => FormValidation.checkSpecializzazioni(
                                    getValues("specializzazione1"), getValues("specializzazione2"), getValues("specializzazione3"), 
                                )
                            }}
                            defaultValue={profileData.specializzazione3 || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.specializzazione3 ? "compilare le specializzazioni in ordine" : null}
                                        label="Altre specializzazioni 3"
                                        id="specializzazione3"
                                        error={errors.specializzazione3 !== undefined}
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('specializzazione3')
                                        }}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Grid item sm={2} >
                            <Controller
                                name="firmaDigitale"
                                id="firmaDigitale"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.firmaDigitale || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="firmaDigitale-label">Firma Digitale *</InputLabel>
                                    <Select
                                        labelId="firmaDigitale-label"
                                        fullWidth
                                        error={errors.firmaDigitale !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setFirmaDigitale(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        <MenuItem key="1" value="1|ARUBA">Aruba</MenuItem>
                                        <MenuItem key="2" value="1|Poste Italiane">Poste Italiane</MenuItem>
                                        <MenuItem key="3" value="3|InfoCert">Infocert</MenuItem>
                                        <MenuItem key="4" value="4|Buffetti">Buffetti</MenuItem>
                                        <MenuItem key="5" value="6|NAMIRIAL">Namirial</MenuItem>
                                        <MenuItem key="6" value="7|CRS REGIONE LOMBARDIA">CRS Regione Lombardia</MenuItem>
                                        <MenuItem key="7" value="8|CNS">CNS</MenuItem>
                                        <MenuItem key="8" value="9|ARCHIWORLDPEC">Archiworldpec</MenuItem>
                                        <MenuItem key="9" value="10|SPIDITALIA">SPIDItalia</MenuItem>
                                        <MenuItem key="10" value="11|INFOCAMERE">Infocamere</MenuItem>
                                        <MenuItem key="11" value="12|CAMERA DI COMMERCIO">Camera di Commercio</MenuItem>
                                        <MenuItem key="12" value="13|NESSUNO">Nessuno</MenuItem>
                                        <MenuItem key="13" value="Altro">Altro (specificare)</MenuItem>
                                    </Select>
                                    </>    
                                )}    
                            />
                            {errors.firmaDigitale && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.firmaDigitale.message}</FormHelperText>}
                        </Grid>
                        <Controller
                            name="firmaDigitaleAltro"
                            control={control}
                            rules={{ }}
                            defaultValue={profileData.firmaDigitaleAltro || ''}
                            render={({value, onChange}) => (
                                <Grid item sm={6} >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        helperText={errors.firmaDigitaleAltro ? errors.firmaDigitaleAltro.message : null}
                                        label="Altra firma digitale"
                                        id="firmaDigitaleAltro"
                                        error={errors.firmaDigitaleAltro}
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('firmaDigitaleAltro')
                                        }}
                                        value={value || ''}
                                    />
                                </Grid>
                            )}    
                        />
                        <Controller
                            name="dipendentePA"
                            control={control}
                            rules={{ 
                                required: "obbligatorio",
                            }}
                            defaultValue={dipendentePA}
                            render={({value, onChange}) => (
                                <Grid item sm={12} >
                                    <FormLabel component="legend">Dipendente di Pubblica Amministrazione</FormLabel>
                                    <RadioGroup 
                                        row
                                        name="dipendentePA" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            setDipendentePA(e.target.value)
                                            setShowAmministrazione(e.target.value === 'true')
                                            if (opzioneIscrizione === '3') {
                                                setOpzioneIscrizione(undefined)
                                            }
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio color="primary"/>} label="Si" />
                                        <FormControlLabel value="false" control={<Radio color="primary"/>} label="No" />
                                    </RadioGroup>
                                    {errors.dipendentePA && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dipendentePA.message}</FormHelperText>}
                                </Grid>
                            )}    
                        />
                        { showAmministrazione && 
                            <Controller
                                name="amministrazione"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.amministrazione || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.amministrazione ? errors.amministrazione.message : null}
                                            label="Amministrazione di appartenenza *"
                                            id="amministrazione"
                                            error={errors.amministrazione !== undefined}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('amministrazione')
                                            }}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            /> 
                        }
                        <Controller
                            name="opzioneIscrizione"
                            control={control}
                            rules={{ 
                                required: "obbligatorio",
                            }}
                            defaultValue={opzioneIscrizione}
                            render={({value, onChange}) => (
                                <Grid item sm={12} >
                                    <FormLabel component="legend">Scegliere una delle opzioni seguenti:</FormLabel>
                                    <RadioGroup 
                                        row
                                        name="opzioneIscrizione" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            setOpzioneIscrizione(e.target.value)
                                            handleIscrizione(e.target.value)
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Iscrizione ad un Ordine/Collegio professionale" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Abilitazione all'esercizio della professione" />
                                        { showAmministrazione && <FormControlLabel value="3" control={<Radio color="primary"/>} label="Certificazione dell'Amministrazione di competenza" />}
                                    </RadioGroup>
                                    {errors.opzioneIscrizione && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.opzioneIscrizione.message}</FormHelperText>}
                                </Grid>
                            )}    
                        />
                        { showOrdine && 
                            <>
                            <Grid item sm={2} >
                                <Controller
                                    name="tipoOrdine"
                                    id="tipoOrdine"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={profileData.tipoOrdine || ''}
                                    render={({onChange, value}) => (
                                        <>
                                        <InputLabel id="tipoOrdine-label">Tipo Ordine *</InputLabel>
                                        <Select
                                            labelId="tipoOrdine-label"
                                            fullWidth
                                            error={errors.tipoOrdine !== undefined}
                                            onChange={(e) => {
                                                onChange(e)
                                                setTipoOrdine(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            <MenuItem key="1" value="Ingegneri">Ingegneri</MenuItem>
                                            <MenuItem key="2" value="Geometri">Geometri</MenuItem>
                                            <MenuItem key="3" value="Geologi">Geologi</MenuItem>
                                            <MenuItem key="4" value="Architetti">Architetti</MenuItem>
                                            <MenuItem key="5" value="Periti Edili">Periti Edili</MenuItem>
                                        </Select>
                                        </>    
                                    )}    
                                />
                                {errors.tipoOrdine && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.tipoOrdine.message}</FormHelperText>}
                            </Grid>
                            <Grid item sm={4} >
                                <Controller
                                    name="ordine"
                                    id="ordine"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={profileData.ordine || ''}
                                    render={({onChange, value}) => (
                                        <>
                                        <InputLabel id="ordine-label">Ordine/Collegio di afferenza *</InputLabel>
                                        <Select
                                            labelId="ordine-label"
                                            fullWidth
                                            error={errors.ordine !== undefined}
                                            onChange={(e) => {
                                                onChange(e)
                                                setOrdine(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            {
                                                province.map( prov =>
                                                    <MenuItem key={prov.abbreviation} value={prov.name}>{prov.name}</MenuItem>
                                                )
                                            } 
                                        </Select>
                                        </>    
                                    )}    
                                />
                                {errors.ordine && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.ordine.message}</FormHelperText>}
                            </Grid>
                            <Controller
                                name="numeroOrdine"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.numeroOrdine || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.numeroOrdine ? errors.numeroOrdine.message : null}
                                            label="N. iscrizione Ordine/Collegio *"
                                            id="numeroOrdine"
                                            error={errors.numeroOrdine !== undefined}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('numeroOrdine')
                                            }}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                            <Grid item sm={2} >
                                <Controller
                                    name="sezioneOrdine"
                                    id="sezioneOrdine"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={profileData.sezioneOrdine || ''}
                                    render={({onChange, value}) => (
                                        <>
                                        <InputLabel id="sezioneOrdine-label">Sezione Ordine *</InputLabel>
                                        <Select
                                            labelId="sezioneOrdine-label"
                                            fullWidth
                                            error={errors.sezioneOrdine !== undefined}
                                            onChange={(e) => {
                                                onChange(e)
                                                setSezioneOrdine(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            <MenuItem key="1" value="A">A</MenuItem>
                                            <MenuItem key="2" value="B">B</MenuItem>
                                            <MenuItem key="3" value="C">C</MenuItem>
                                            <MenuItem key="4" value="A B">A B</MenuItem>
                                            <MenuItem key="5" value="A C">A C</MenuItem>
                                            <MenuItem key="6" value="B C">B C</MenuItem>
                                            <MenuItem key="7" value="A B C">A B C</MenuItem>
                                        </Select>
                                        </>    
                                    )}    
                                />
                                {errors.sezioneOrdine && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.sezioneOrdine.message}</FormHelperText>}
                            </Grid>
                            </>
                        }
                        { showProfessione && 
                            <Controller
                                name="abilitazioneProfessione"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.abilitazioneProfessione || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.abilitazioneProfessione ? errors.abilitazioneProfessione.message : null}
                                            label="Professione *"
                                            id="abilitazioneProfessione"
                                            error={errors.abilitazioneProfessione !== undefined}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('abilitazioneProfessione')
                                            }}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                        }
                        { showCertificazione && showAmministrazione &&
                            <>
                            <Controller
                                name="certPARilasciataDa"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.certPARilasciataDa || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.certPARilasciataDa ? errors.certPARilasciataDa.message : null}
                                            label="Rilasciata da *"
                                            id="certPARilasciataDa"
                                            error={errors.certPARilasciataDa !== undefined}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('certPARilasciataDa')
                                            }}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                <Controller
                                    name="certPAData"
                                    control={control}
                                    rules={{
                                        required: "obbligatorio",
                                    }}
                                    defaultValue={certPAData}
                                    render={({value, onChange}) => (
                                        <Grid item sm={5} >
                                            <KeyboardDatePicker
                                                disableFuture
                                                disableToolbar
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="certPAData"
                                                label="Data *"
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('certPAData')
                                                    setCertPAData(e)
                                                }}
                                                invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                maxDateMessage="Data non valida"
                                            />
                                            {errors.certPAData && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.certPAData.message}</FormHelperText>}
                                        </Grid>
                                    )} 
                                />
                            </MuiPickersUtilsProvider>
                            <Controller
                                name="certPAProtocollo"
                                control={control}
                                rules={{ 
                                    required: "obbligatorio",
                                }}
                                defaultValue={profileData.certPAProtocollo || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={7} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.certPAProtocollo ? errors.certPAProtocollo.message : null}
                                            label="N. protocollo *"
                                            id="certPAProtocollo"
                                            error={errors.certPAProtocollo !== undefined}
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('certPAProtocollo')
                                            }}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                            </>
                        }
                    </Grid>
                    <div style={{textAlign: "right"}}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Aggiorna
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}