import http from "../utils/http-common";

class DocService {
    
    checkModuloIscrizione(usernameProvided) {
        return http.get("/pdfGeneration/checkModuloIscrizione?usernameProvided="+usernameProvided)
    }

    checkModuloIscrizioneNTN(usernameProvided) {
        return http.get("/pdfGeneration/checkModuloIscrizioneNTN?usernameProvided="+usernameProvided)
    }

    getModuloIscrizione(usernameProvided) {
        return http.get("/pdfGeneration/creaModuloIscrizione?usernameProvided="+usernameProvided, { responseType: 'blob'})
    }

    getModuloIscrizioneNTN(usernameProvided) {
        return http.get("/pdfGeneration/creaModuloIscrizioneNTN?usernameProvided="+usernameProvided, { responseType: 'blob'})
    }

    getEstrazioneUtenti(data) {
        return http.post("/excelGeneration/estrazioneUtenti", data, {responseType: 'blob'})
    }
}

export default new DocService();