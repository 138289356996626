import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import RestService from '../../services/RestService';
import {Tabs, Tab } from '@material-ui/core';
import 'fontsource-roboto';
import Account from './Account'
import Abilitazioni from './Abilitazioni'
import Profile from './Profile'
import Elenchi from './Elenchi'
import Documenti from './Documenti'
import Bonifici from './Bonifici'
import {UserContext} from '../../context/UserContext'

export default function User(props) {
    const user = useContext(UserContext);
    const [accountData, setAccountData] = useState(null)
    const [profileData, setProfileData] = useState(null)
    const [elenchiData, setElenchiData] = useState(null)
    const [bonificiData, setBonificiData] = useState(null)
    const [documentiData, setDocumentiData] = useState(null)
    const usernameProvided = user.isAdmin() && props.usernameProvided !== undefined ? props.usernameProvided : ''
    const tabIndex = usernameProvided ? 1 : 0

    useEffect(() => {
        const fetchData = async () => {
          RestService.getUserData(usernameProvided)
            .then((response) => {
                setAccountData(response.data)
                if (usernameProvided) {
                    props.refreshTable()
                }
                setProfileData(response.data)
                setElenchiData(response.data.elenchi)
                setBonificiData(response.data.bonifici)
                setDocumentiData(response.data.documenti)
            }).catch((e) => {
                console.log(e)
            })
        };
     
        fetchData();
      }, [props.usernameProvided]);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addDocumento = (data) => {
        documentiData.push(data)
        setDocumentiData(documentiData)
    }

    const removeDocumento = (id) => {
        const newData = documentiData.filter((e) => e.tipo !== id)
        setDocumentiData(newData)
    }

    const addRicevuta = (data) => {
        const newData = bonificiData.filter((e) => e.anno !== data.anno)
        newData.push(data)
        setBonificiData(newData)
    }

    const removeRicevuta = (anno) => {
        const bonificoNoRicevuta = bonificiData.filter((e) => e.anno === anno)[0]
        bonificoNoRicevuta.ricevuta = null
        const newData = bonificiData.filter((e) => e.anno !== anno)
        newData.push(bonificoNoRicevuta)
        setBonificiData(newData)
    }

    const deleteBonifico = (anno) => {
        const newData = bonificiData.filter((e) => e.anno !== anno)
        setBonificiData(newData)
    }

    return (
        <>
        <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Account" />
            {usernameProvided && <Tab label="Abilitazioni" />}
            <Tab label="Anagrafica" />
            <Tab label="Elenchi Valutatori" />
            <Tab label="Bonifici" />
            <Tab label="Documenti" />
            
        </Tabs>
        {accountData &&
            <> 
                <TabPanel value={value} index={0}>
                    <Account data={accountData} refreshData={setAccountData} refreshTable={props.refreshTable}/>
                </TabPanel>
                {
                    usernameProvided &&
                    <TabPanel value={value} index={1}>
                        <Abilitazioni data={accountData} refreshTable={props.refreshTable}/>
                    </TabPanel>
                }
                <TabPanel value={value} index={tabIndex + 1}>
                    <Profile data={profileData} refreshData={setProfileData} />
                </TabPanel>
                <TabPanel value={value} index={tabIndex + 2}>
                    <Elenchi data={elenchiData} refreshData={setElenchiData} user={accountData.username}/>
                </TabPanel>
                <TabPanel value={value} index={tabIndex + 3}>
                    <Bonifici data={bonificiData} user={accountData} iscrizione={new Date(accountData.dataCreazione)} 
                        refreshData={setBonificiData} addRicevuta={addRicevuta} removeRicevuta={removeRicevuta} deleteBonifico={deleteBonifico}/>
                </TabPanel>
                <TabPanel value={value} index={tabIndex + 4}>
                    <Documenti data={documentiData} user={accountData.username} addDocumento={addDocumento} removeDocumento={removeDocumento} />
                </TabPanel>
            </>
        }
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};