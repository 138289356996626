import React, { useState, useContext, useEffect } from 'react'
import AuthenticationService from '../services/AuthenticationService';
import { useParams } from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles';
import {Button, CssBaseline, Typography, Container, Backdrop, CircularProgress} from '@material-ui/core';
import 'fontsource-roboto';
import { useHistory } from 'react-router-dom';
import {UserContext} from '../context/UserContext'

const useStyles = makeStyles((theme) => ({
    inputElement: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[400],
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: theme.palette.grey[600],
        },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    back: {
        margin: theme.spacing(3, 2, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));

export default function EmailConfirmation() {
    const user = useContext(UserContext)
    const classes = useStyles();
    const { token } = useParams();
    const [ emailConfirmed, setEmailConfirmed ] = useState(false)
    const [ confirming, setConfirming ] = useState(true)
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
          AuthenticationService.confirmEmail(token)
            .then((response) => {
                setEmailConfirmed(response.data.confirmed === 'ok')
                setConfirming(false)
                if (user.loggedIn){
                    user.setEnabled(1)
                }
            }).catch((e) => {
                console.log(e)
            })
        };
     
        fetchData();
      });

      return (
          <>
        { confirming ? 
            <Backdrop className={classes.backdrop} open={true}><CircularProgress color="inherit" /></Backdrop> 
            : 
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {emailConfirmed && <Typography>La tua email è stata confermata correttamente!</Typography>}
                    {!emailConfirmed && <Typography>Token errato/scaduto, email non confermata!</Typography>}
                </div>
                <div style={{textAlign: "right"}}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.back}
                        onClick={() => history.push('/')}
                    >
                        Torna alla Home
                    </Button>
                </div>
            </Container>}
        </>
      )
}