import React, { useState, useRef, useEffect, useContext } from 'react'
import {FormHelperText, Button, CssBaseline, TextField, Typography, Container} from '@material-ui/core';
import {Select, MenuItem, InputLabel, FormControlLabel, Checkbox} from '@material-ui/core';
import {FormControl, OutlinedInput, InputAdornment, IconButton, Link} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';
import RestService from '../services/RestService';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import {ModalContext} from '../context/ModalContext'
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    inputElement: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[400],
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: theme.palette.grey[600],
        },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    back: {
        margin: theme.spacing(3, 2, 2),
    },
    password: {
        marginTop: theme.spacing(2)
    },
    link: {
        fontWeight: 'bold',
        color: 'mediumBlue'
    }
  }));

export default function SignIn() {
    const modal = useContext(ModalContext)
    const classes = useStyles();
    const history = useHistory();
    const [password, setPassword] = useState('')
    const [sezioni, setSezioni] = useState([])
    const signinForm = useRef(null)
    const [showPassword, setShowPassord] = useState(false)
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const [checkCodice, setCheckCodice] = useState(false)
    const [checkSanzioni, setCheckSanzioni] = useState(false)
    const { handleSubmit, control, errors, reset, clearErrors } = useForm({

    });
    
    const handleReturnClick = () => history.push('/')
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const toggleShowPassword = () => {
        setShowPassord(!showPassword)
    }

    useEffect(() => {
        const fetchData = async () => {
          RestService.getSezioniDropdown()
            .then((response) => {
                setSezioni(response.data)
            }).catch((e) => {
                console.log(e)
            })
        }
     
        fetchData()
      }, [])

    const submitForm = e => {
        modal.toggleBackdrop()
        if (!checkPrivacy || !checkCodice || !checkSanzioni){
            modal.setDialogTitle('Errore')
            modal.setDialogMessage('Devi selezionare le tre spunte per poterti registrare')
            modal.toggleBackdrop()
            modal.toggleDialog() 
            return
        }
        const data = new FormData(signinForm.current)
        data.append('password', password)
        AuthenticationService.executeRegistration(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Registrazione')
                if (response.data.message === 'ok') {
                    modal.setDialogMessage('Registrazione effettuata con successo: ti abbiamo inviato una email, usa il collegamento contenuto per confermare il tuo indirizzo di posta elettronica')
                    modal.setReturnHome(true)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleBackdrop()
                modal.toggleDialog()
                reset(
                    {
                      username: '',
                      nome: '',
                      cognome: '',
                      cf: '',
                      sezione: '',
                      password: ''
                    },
                    {
                      errors: true,
                      dirtyFields: true
                    }
                );
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Registrazione a NET.PRO
                </Typography>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form} ref={signinForm} >
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "obbligatorio",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "indirizzo email non valido"
                            }
                        }}
                        as={
                            <TextField
                                className={classes.inputElement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.username ? errors.username.message : null}
                                id="username"
                                label="Indirizzo email"
                                autoFocus
                                onChange={() => clearErrors('username')}
                                error={errors.username !== undefined}
                            />
                        }
                    />
                    <Controller
                        name="nome"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "obbligatorio",
                        }}
                        as={
                            <TextField
                                className={classes.inputElement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.nome ? errors.nome.message : null}
                                label="Nome"
                                id="nome"
                                onChange={() => clearErrors('nome')}
                                error={errors.nome !== undefined}
                            />
                        }    
                    />
                    <Controller
                        name="cognome"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "obbligatorio",
                        }}
                        as={
                            <TextField
                                className={classes.inputElement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.cognome ? errors.cognome.message : null}
                                label="Cognome"
                                id="cognome"
                                onChange={() => clearErrors('cognome')}
                                error={errors.cognome !== undefined}
                            />
                        }    
                    />
                    <Controller
                        name="cf"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                            minLength: 16,
                            maxLength: 16,
                        }}
                        as={
                            <TextField
                                className={classes.inputElement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText= {errors.cf ? "campo obbligatorio di 16 caratteri" : null}
                                label="Codice Fiscale"
                                id="cf"
                                onChange={(e) => {clearErrors('cf')}}
                                error={errors.cf !== undefined}
                            />
                        }    
                    />
                    <InputLabel id="sezione-label">Sezione *</InputLabel>
                    <Controller
                        name="sezione"
                        control={control}
                        defaultValue=""
                        labelId="sezione-label"
                        id="sezione"
                        rules={{
                            required: "obbligatorio",
                        }}
                        as={
                            <Select
                                fullWidth
                                variant="outlined"
                                error={errors.sezione !== undefined}
                            >
                                {
                                    sezioni.map( sez =>
                                        <MenuItem key={sez.id} value={sez.id}>{sez.nome}</MenuItem>
                                    )
                                }               
                            </Select>
                        }    
                    />
                    {errors.sezione && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.sezione.message}</FormHelperText>}
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        id="password"
                        rules={{
                            required: true,
                            minLength: 8
                        }}
                        render={({onChange}) => (
                            <>
                            <FormControl variant="outlined" fullWidth className={classes.password}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => {
                                        onChange(e)
                                        handlePasswordChange(e)
                                    }}
                                    error={errors.password !== undefined}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                                edge="end"
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                            {errors.password && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>campo obbligatorio di almeno 8 caratteri</FormHelperText>}
                            <PasswordStrengthMeter password={password} />
                            </>
                        )}
                    />
                   <FormControlLabel
                        control={
                            <Controller
                                name="checkPrivacy"
                                id="checkPrivacy"
                                control={control}
                                defaultValue={checkPrivacy}
                                render={(props) => (
                                    <Checkbox
                                        checked={checkPrivacy}
                                        color="primary"
                                        onChange={(e) => {
                                            clearErrors('checkPrivacy')
                                            setCheckPrivacy(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                        }
                        label= {
                            <div>
                                <span>Dichiaro di aver letto e accettato la </span>
                                <Link 
                                    href={process.env.PUBLIC_URL + '/docs/Privacy.pdf'}
                                    target="_blank"
                                    className={classes.link}
                                >
                                    Privacy
                                </Link>
                            </div>
                        }
                    />
                   <FormControlLabel
                        control={
                            <Controller
                                name="checkCodice"
                                control={control}
                                defaultValue={checkCodice}
                                render={(props) => (
                                    <Checkbox
                                        checked={checkCodice}
                                        color="primary"
                                        onChange={(e) => {
                                            clearErrors('checkCodice')
                                            setCheckCodice(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                        }
                        label= {
                            <div>
                                <span>Dichiaro di aver letto e accettato il </span>
                                <Link 
                                    href={process.env.PUBLIC_URL + '/docs/CODICE ETICO E DI CONDOTTA NET.PRO.pdf'}
                                    target="_blank"
                                    className={classes.link}
                                >
                                    Codice Etico
                                </Link>
                            </div>
                        }
                    />
                    <FormControlLabel
                        style={{display:'table',  textAlign:'justify'}}
                        control={
                            <Controller
                                name="checkSanzioni"
                                control={control}
                                defaultValue={checkSanzioni}
                                render={(props) => (
                                    <div style={{display:'table-cell'}}>
                                        <Checkbox
                                            checked={checkSanzioni}
                                            color="primary"
                                            onChange={(e) => {
                                                clearErrors('checkSanzioni')
                                                setCheckSanzioni(e.target.checked)
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        }
                        label= {`Consapevole delle sanzioni penali richiamate dall’art. 76 del D.P.R. 28/12/2000 n. 445 e 
                                s.m.i. in caso di dichiarazioni mendaci e di formazione o uso di atti falsi, dichiaro che
                                le informazioni fornite corrispondono a verità.`}
                    />
                    <div style={{textAlign: "right"}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.back}
                            onClick={handleReturnClick}
                        >
                            Indietro
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Invia
                        </Button>
                    </div>
                </form>
            </div>
        </Container> 
    )
}