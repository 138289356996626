import React, { useContext, useState, useEffect } from 'react'
import RestService from '../../services/RestService';
import {Button, CssBaseline, Select, Container, MenuItem, InputLabel, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import {ModalContext} from '../../context/ModalContext'
import 'fontsource-roboto';
import Utils from '../../utils/Utils'
import * as Constants from '../../utils/Constants'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function Abilitazioni(props) {
    const modal = useContext(ModalContext)
    const [sezione, setSezione] = useState(null)
    const [livello, setLivello] = useState(null)
    const [abilitato, setAbilitato] = useState(null)
    const [sezioni, setSezioni] = useState([])
    const classes = useStyles()
    const { handleSubmit, control, errors, clearErrors, reset } = useForm({
        defaultValues: {
            nome: props.data.nome,
            cognome: props.data.cognome,
            cf: props.data.cf
          }
    })

    useEffect(() => {
        const fetchSezioni = async () => {
            RestService.getSezioniDropdown()
              .then((response) => {
                  setSezioni(response.data)
              }).catch((e) => {
                  console.log(e)
              })
        }
        
        setSezione(props.data.sezione.id)
        setAbilitato(props.data.abilitato)
        setLivello(props.data.livello)
        reset({
            sezione: props.data.sezione.id,
            abilitato: props.data.abilitato,
            livello: props.data.livello
        })
        clearErrors()
        fetchSezioni()
    }, [props.data])

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('username', props.data.username)
        data.append('abilitato', e.abilitato)
        data.append('sezione', e.sezione)
        data.append('livello', e.livello)
        RestService.updateUserEnabled(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data.errorMessage) {
                    modal.setDialogMessage(response.data.errorMessage)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    props.refreshTable()
                    modal.toggleDialog()
                }
            }).catch((e) => {
                modal.setDialogMessage('Errore di comunicazione col server')
                modal.toggleDialog()
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form}>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <Controller
                                name="sezione"
                                id="sezione"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={sezione || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="sezione-label">Sezione *</InputLabel>
                                    <Select
                                        labelId="sezione-label"
                                        fullWidth
                                        error={errors.sezione !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setSezione(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        {
                                            sezioni.map( sez =>
                                                <MenuItem key={sez.id} value={sez.id}>{sez.nome}</MenuItem>
                                            )
                                        }   
                                    </Select>
                                    </>    
                                )}   
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Controller
                                name="livello"
                                id="livello"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={livello || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="livello-label">Livello *</InputLabel>
                                    <Select
                                        labelId="livello-label"
                                        fullWidth
                                        error={errors.livello !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setLivello(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        <MenuItem key={1} value={Constants.USER}>{Utils.getPermessi(Constants.USER)}</MenuItem>
                                        <MenuItem key={2} value={Constants.SECTION_LEADER}>{Utils.getPermessi(Constants.SECTION_LEADER)}</MenuItem>
                                        <MenuItem key={3} value={Constants.ADMIN}>{Utils.getPermessi(Constants.ADMIN)}</MenuItem>  
                                    </Select>
                                    </>    
                                )}   
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Controller
                                name="abilitato"
                                id="abilitato"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={abilitato || ''}
                                render={({onChange, value}) => (
                                    <>
                                    <InputLabel id="abilitato-label">Abilitazione *</InputLabel>
                                    <Select
                                        labelId="abilitato-label"
                                        fullWidth
                                        error={errors.abilitato !== undefined}
                                        onChange={(e) => {
                                            onChange(e)
                                            setAbilitato(e.target.value)
                                        }}
                                        value={value}
                                    >
                                        <MenuItem key={0} value={0}>{Utils.getAbilitazione(0)}</MenuItem>
                                        <MenuItem key={1} value={1}>{Utils.getAbilitazione(1)}</MenuItem>
                                        <MenuItem key={2} value={2}>{Utils.getAbilitazione(2)}</MenuItem>   
                                    </Select>
                                    </>    
                                )}   
                            />
                        </Grid>
                        <div style={{textAlign: "right", width: "100%"}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Aggiorna
                            </Button>
                        </div>
                    </Grid>
                </form>    
            </div>
        </Container>
    )
}