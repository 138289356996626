import React, { useContext, useState, useEffect } from 'react'
import StorageService from '../../services/StorageService';
import RestService from '../../services/RestService';
import {Button, Grid, Typography, FormHelperText, TextField} from '@material-ui/core';
import {ModalContext} from '../../context/ModalContext'
import {makeStyles} from '@material-ui/core/styles';
import {Check, Clear} from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { useForm, Controller } from 'react-hook-form';
import Utils from '../../utils/Utils'
import DateFnsUtils from '@date-io/date-fns'
import {it} from "date-fns/locale"
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import { UserContext } from '../../context/UserContext'
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    documento:{
        textAlign: 'center',
        backgroundColor: red[50],
        padding: theme.spacing(2),
        '&.loaded':{
            backgroundColor: green[50],
        },
        marginBottom: theme.spacing(4)
    },
    docButton: {
        marginRight: theme.spacing(2),
    },
    formParagraphFirst: {
        width:'100%',
        fontWeight: 'bold'
    },
  }));

export default function Bonifico(props) {
    const modal = useContext(ModalContext)
    const userCtx = useContext(UserContext);
    const classes = useStyles()
    const [data, setData] = useState(props.data)
    const [user, setUser] = useState(props.user)
    const [showUpload, setShowUpload] = useState(props.data && props.data.importo)
    const [dataBonifico, setDataBonifico] = useState(data && Utils.parseDate(data.data))
    const [loaded, setLoaded] = useState(data && data.ricevuta)
    const [selectedFile, setSelectedFile] = useState(null)
    const documentClasses = loaded ? `${classes.documento} loaded` : `${classes.documento}`
    const [link, setLink] = useState(null)
    const { handleSubmit, control, errors, clearErrors, reset } = useForm({})

    useEffect(() => {
        setData(props.data)
        setUser(props.user)
        setShowUpload(props.data && props.data.importo)
        setDataBonifico(props.data && Utils.parseDate(props.data.data))
        setSelectedFile(null)
        setLink(null)
        setLoaded(props.data && props.data.ricevuta)
        
        reset({
            importo: props.data && Utils.formatCurrency(props.data.importo),
            dataBonifico: props.data && Utils.parseDate(props.data.data),
            anno: props.data && props.data.anno
        })
    }, [props.data])

    const open = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Apertura')
        let formData = new FormData()
        formData.append("username", data.username)
        formData.append("anno", data.anno)

        StorageService.openRicevutaBonifico(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data.message === 'ok') {
                    setLink(response.data.link)
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Problema nell\'apertura del file')
                modal.toggleDialog()
            });
    }

    const deleteFile = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Cancellazione')
        let formData = new FormData()
        formData.append("username", data.username)
        formData.append("anno", data.anno)
    
        StorageService.deleteRicevutaBonifico(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data.message === 'ok') {
                    modal.setDialogMessage('Cancellazione effettuata con successo')
                    props.removeRicevuta(props.anno)
                    setLoaded(false)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Cancellazione del file fallita')
                modal.toggleDialog()
            });
    }

    const upload = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Upload')
        let formData = new FormData()
        formData.append("ricevuta", selectedFile)
        formData.append("username", props.user.username)
        formData.append("anno", props.anno)
    
        StorageService.uploadRicevutaBonifico(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data) {
                    modal.setDialogMessage('Upload effettuato con successo')
                    props.addRicevuta(response.data)
                    setData(response.data)
                    setLoaded(true)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Upload del file fallito')
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Upload del file fallito')
                modal.toggleDialog()
                setSelectedFile(null)
            });

        setSelectedFile(null)
    }

    const fileSelectionHandler = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('importo', Utils.parseCurrency(e.importo))
        data.append('data', Utils.formatDate(dataBonifico))
        data.append('anno', props.anno)
        data.append("username", props.user.username)
        RestService.updateBonifico(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data) {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    props.refreshData(response.data)
                    setShowUpload(true)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }

    const resetBonifico = () => {
        setData([])
        setDataBonifico(null)
        setShowUpload(false)
        reset()
    }

    const deleteBonifico = () => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('username', props.user.username)
        data.append('anno', props.anno)
        RestService.deleteBonifico(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Cancellazione')
                if (response.data) {
                    modal.setDialogMessage('Cancellazione effettuata con successo')
                    resetBonifico()
                    props.deleteBonifico(props.anno)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }
    
    return (
        <>
        <Typography variant="h6" className={classes.formParagraphFirst}>Bonifico {props.anno}</Typography>
        <Typography className={classes.formParagraphFirst}>
            Causale: <span style={{fontWeight:"normal"}}>Quota Associativa {user.sezione.nome} - anno {props.anno} - {user.nome} {user.cognome}</span> 
        </Typography>
        <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitForm)} className={classes.form} >
                <Grid container spacing={1}>
                    <Controller
                        name="importo"
                        control={control}
                        rules={{
                            required: "obbligatorio",
                            pattern: {
                                value: /^(0|[1-9]\d*)(,([1-9]|([0-9][1-9])))?$/,
                            }
                        }}
                        defaultValue={(data && Utils.formatCurrency(data.importo)) || ''}
                        render={({value, onChange}) => (
                            <Grid item sm={4} >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    helperText={errors.importo ? "importo mancante o formato errato" : null}
                                    label="Importo *"
                                    id="importo"
                                    onChange={(e) => {
                                        onChange(e) 
                                        clearErrors('importo')
                                    }}
                                    error={errors.importo !== undefined}
                                    value={value || ''}
                                />
                            </Grid>
                        )}   
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                        <Controller
                            name="dataBonifico"
                            control={control}
                            rules={{
                                required: "obbligatorio",
                            }}
                            defaultValue={dataBonifico || null}
                            render={({value, onChange}) => (
                                <Grid item sm={4} >
                                    <KeyboardDatePicker
                                        disableFuture
                                        disableToolbar
                                        size="small"
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="dataBonifico"
                                        label="Data *"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e) 
                                            clearErrors('dataBonifico')
                                            setDataBonifico(e)
                                        }}
                                        invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                        maxDateMessage="Data non valida"
                                    />
                                    {errors.dataBonifico && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataBonifico.message}</FormHelperText>}
                                </Grid>
                            )} 
                        />
                    </MuiPickersUtilsProvider>
                    <Grid item sm={4} style={{textAlign: 'right', paddingTop: '20px'}}>
                        {
                            data &&
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.docButton}
                                onClick={deleteBonifico}
                            >
                                Cancella
                            </Button>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Aggiorna
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
        {   showUpload &&
            <div className={documentClasses}>
                <Grid container spacing={1}>
                    <Grid item sm={1} >
                        {loaded && 
                            <Check
                                htmlColor='green'
                                fontSize='large'
                            />
                        }
                        {!loaded && 
                            <Clear
                                htmlColor='red'
                                fontSize='large'
                            />
                        }
                    </Grid>
                    <Grid item sm={3}>
                        <Typography style={{marginTop:'5px'}}>Ricevuta</Typography>
                    </Grid>
                    <Grid item sm={8} >
                        { loaded && userCtx.isAdmin() &&
                            <Button
                                className={classes.docButton}
                                variant="outlined"
                                color="primary"
                                component="span" 
                                onClick={deleteFile}   
                            >
                                Cancella file
                            </Button>
                        }
                        { !loaded &&
                            <label htmlFor={`btn-upload-${props.tipo}`} >   
                                <input
                                    id={`btn-upload-${props.tipo}`}
                                    name={`btn-upload-${props.tipo}`}
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={fileSelectionHandler} 
                                />
                                <Button
                                    className={classes.docButton}
                                    variant="contained"
                                    color="primary"
                                    component="span" >
                                    Seleziona File
                                </Button>
                            </label>
                        }
                        { !loaded && selectedFile && selectedFile.size > 0 &&
                            <Button
                                className={classes.docButton}
                                variant="contained"
                                color="primary"
                                component="span" 
                                onClick={upload}    
                            >
                                Upload
                            </Button>              
                        }
                        { !loaded &&selectedFile && selectedFile.size > 0 && 
                            <>
                                <br/>
                                <Typography variant="caption">
                                    {selectedFile.name}
                                </Typography>
                            </>
                        }
                        { loaded &&
                            <Button
                                className={classes.docButton}
                                variant="contained"
                                color="primary"
                                component="span" 
                                onClick={open}    
                        >
                            Crea Link
                            </Button>
                        }
                        { loaded && link &&
                            <Button
                                className={classes.docButton}
                                variant="contained"
                                color="primary"
                                component="span" 
                                onClick={() => window.open(link, '_blank')}  
                            >
                            Apri
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        }
        </>
    )
}