import React, { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { Typography, LinearProgress} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const PasswordLinearProgress = withStyles((theme) => ({
    colorPrimary: {
        backgroundColor: theme.palette.grey[200],
    },
    bar: {
        backgroundColor: '#0077e6',
    },
}))(LinearProgress);

export default function PasswordStrengthMeter(props) {
    const [progress, setProgress] = useState(0);
    const [label, setLabel] = useState('Inesistente');
    
    useEffect(() => {
        const testedResult = zxcvbn(props.password)
        const score = testedResult.score || 0
        setProgress(score * 25)
        switch (score) {
            case 0:
                setLabel('Inesistente')
                break
            case 1:
                setLabel('Debole')
                break
            case 2:
                setLabel('Insufficiente')
                break
            case 3:
                setLabel('Buona')
                break
            case 4:
                setLabel('Ottima')
                break
            default:
                setLabel('Inesistente')
                break
            }
    }, [props]);

    const useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    const classes = useStyles();
    
    return (
      <div className={classes.root}>
        <PasswordLinearProgress variant="determinate" value={progress}  />
        <Typography variant="caption">Robustezza Password: <strong>{label}</strong></Typography>
      </div>
    )
}