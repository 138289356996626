import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import * as Constants from '../utils/Constants';

const UserContext = React.createContext();

class UserContextProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor (props) {
        super(props);
        const { cookies } = props;

        this.setLogged = this.setLogged.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setName = this.setName.bind(this);
        this.setLevel = this.setLevel.bind(this);
        this.setEnabled = this.setEnabled.bind(this);
        this.isAdmin = this.isAdmin.bind(this);
        this.isSectionLeader = this.isSectionLeader.bind(this);


        this.state = {
            loggedIn: cookies.get('loggedIn'),
            username: cookies.get('username'),
            name: cookies.get('name'),
            level: Number(cookies.get('level')),
            enabled: Number(cookies.get('enabled')),
            setLogged: this.setLogged,
            setUsername: this.setUsername,
            setName: this.setName,
            setLevel: this.setLevel,
            setEnabled: this.setEnabled,
            isAdmin: this.isAdmin,
            isSectionLeader: this.isSectionLeader
        }
    }

    setLogged (logged) {
        this.setState( () => ({
            loggedIn: logged
        }));
        const { cookies } = this.props;
        if (logged) {
            cookies.set('loggedIn', 'true', {sameSite: 'strict', path: '/'})
        } else {
            cookies.remove('loggedIn')
            cookies.remove('username')
            cookies.remove('name')
            cookies.remove('level')
            cookies.remove('enabled')
        }
        
    }

    setUsername (un) {
        this.setState( () => ({
            username: un
        }));
        const { cookies } = this.props;
        cookies.set('username', un, {sameSite: 'strict', path: '/'})
    }

    setName (nm) {
        this.setState( () => ({
            name: nm
        }));
        const { cookies } = this.props;
        cookies.set('name', nm, {sameSite: 'strict', path: '/'})
    }

    setLevel (lvl) {
        this.setState( () => ({
            level: lvl
        }));
        const { cookies } = this.props;
        cookies.set('level', lvl, {sameSite: 'strict', path: '/'})
    }

    setEnabled (enb) {
        this.setState( () => ({
            enabled: enb
        }));
        const { cookies } = this.props;
        cookies.set('enabled', enb, {sameSite: 'strict', path: '/'})
    }

    isAdmin() {
        return this.state.level === Constants.ADMIN
    }

    isSectionLeader() {
        return this.state.level === Constants.SECTION_LEADER
    }

    render () {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export {UserContext}
export default withCookies(UserContextProvider)