import React, { useContext, useState, useEffect } from 'react'
import RestService from '../../services/RestService';
import {Button, CssBaseline, TextField, Container, FormHelperText} from '@material-ui/core';
import {FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import {ModalContext} from '../../context/ModalContext'
import PasswordStrengthMeter from '../PasswordStrengthMeter';
import AuthenticationService from '../../services/AuthenticationService';
import FormValidation from '../../utils/FormValidation'
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    password: {
        marginTop: theme.spacing(4)
    },
    upper: {
        textTransform: "uppercase"
    }
  }));

export default function Account(props) {
    const modal = useContext(ModalContext)
    const accountData = props.data
    const classes = useStyles()
    const { handleSubmit, control, errors, clearErrors, reset } = useForm({
        defaultValues: {
            nome: accountData.nome,
            cognome: accountData.cognome,
            cf: accountData.cf
          }
    })
    const { handleSubmit: handleSubmit2, control: control2, errors: errors2 } = useForm({})
    const [password, setPassword] = useState('')
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const [showPassword, setShowPassord] = useState(false)
    const toggleShowPassword = () => {
        setShowPassord(!showPassword)
    }

    useEffect(() => {
        reset({
            username: accountData.username,
            sezione: accountData.sezione.id,
            nome: accountData.nome,
            cognome: accountData.cognome,
            cf: accountData.cf,
        })
        clearErrors()
    }, [props.data])

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        data.append('username', props.data.username)
        data.append('nome', e.nome)
        data.append('cognome', e.cognome)
        data.append('cf', e.cf)
        RestService.updateUserAccount(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data.errorMessage) {
                    modal.setDialogMessage(response.data.errorMessage)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    props.refreshData(response.data)
                    props.refreshTable && props.refreshTable()
                    modal.toggleDialog()
                }
            }).catch((e) => {
                modal.setDialogMessage('Errore di comunicazione col server')
                modal.toggleDialog()
            })
    }

    const submitPassword = e => {
        modal.toggleBackdrop()
        AuthenticationService.changePassword(null, props.data.username, password)
        .then((response) => {
            modal.toggleBackdrop()
            modal.setDialogTitle('Esito Modifica Password')
            if (response.data.esito === 'ok') {
                modal.setDialogMessage('Password modificata con successo')
                modal.toggleDialog()
            } else {
                modal.setDialogMessage('Errore nella modifica della password')
                modal.toggleDialog()
            }
        }).catch((e) => {
            modal.setDialogMessage('Errore nella comunicazione col server')
            modal.toggleDialog()
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form}>
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Username"
                        inputProps={
                            { readOnly: true, }
                        }
                        value={accountData.username || ''}
                    />
                     <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="sezione"
                        label="Sezione"
                        inputProps={
                            { readOnly: true, }
                        }
                        value={accountData.sezione.nome || ''}
                    />
                    <Controller
                        name="nome"
                        control={control}
                        rules={{
                            required: "obbligatorio",
                        }}
                        as={
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.nome ? errors.nome.message : null}
                                label="Nome *"
                                id="nome"
                                onChange={() => clearErrors('nome')}
                                error={errors.nome !== undefined}
                            />
                        }    
                    />
                    <Controller
                        name="cognome"
                        control={control}
                        rules={{
                            required: "obbligatorio",
                        }}
                        as={
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.cognome ? errors.cognome.message : null}
                                label="Cognome *"
                                id="cognome"
                                onChange={() => clearErrors('cognome')}
                                error={errors.cognome !== undefined}
                            />
                        }    
                    />
                    <Controller
                        name="cf"
                        control={control}
                        rules={{
                            required: true,
                            minLength: 16,
                            maxLength: 16,
                            validate: FormValidation.checkCf
                        }}
                        as={
                            <TextField
                                className={classes.inputElement}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText= {
                                    errors.cf ? (
                                        errors.cf.type === "validate" ? "codice fiscale formalmente errato" : "campo obbligatorio di 16 caratteri"
                                    ) : null
                                }
                                label="Codice Fiscale *"
                                id="cf"
                                onChange={(e) => {clearErrors('cf')}}
                                error={errors.cf !== undefined}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                            />
                        }    
                    />
                    <div style={{textAlign: "right"}}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Aggiorna
                        </Button>
                    </div>
                </form>
                <form onSubmit={handleSubmit2(submitPassword)} className={classes.form}>
                    <Controller
                        name="password"
                        control={control2}
                        defaultValue=""
                        id="password"
                        rules={{
                            required: true,
                            minLength: 8
                        }}
                        render={({onChange}) => (
                            <>
                            <FormControl variant="outlined" fullWidth className={classes.password}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => {
                                        onChange(e)
                                        handlePasswordChange(e)
                                    }}
                                    error={errors2.password !== undefined}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                                edge="end"
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                    autoComplete='new-password'
                                />
                            </FormControl>
                            {errors2.password && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>campo obbligatorio di almeno 8 caratteri</FormHelperText>}
                            <PasswordStrengthMeter password={password} />
                            </>
                        )}
                    />
                    <div style={{textAlign: "right"}}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Cambia Password
                        </Button>
                    </div> 
                </form>
            </div>

        </Container>
    )
}