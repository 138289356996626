import React, { Component } from 'react';

const ModalContext = React.createContext();

class ModalContextProvider extends Component {

    constructor (props) {
        super(props);

        this.toggleBackdrop = this.toggleBackdrop.bind(this)
        this.toggleDialog = this.toggleDialog.bind(this)
        this.setDialogMessage = this.setDialogMessage.bind(this)
        this.setDialogTitle = this.setDialogTitle.bind(this)
        this.setReturnHome = this.setReturnHome.bind(this)

        this.state = {
            showBackdrop: false,
            showDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            returnHome: false,
            toggleBackdrop: this.toggleBackdrop,
            toggleDialog: this.toggleDialog,
            setDialogMessage: this.setDialogMessage,
            setDialogTitle: this.setDialogTitle,
            setReturnHome: this.setReturnHome
        }
    }

    toggleBackdrop () {
        this.setState( () => ({
            showBackdrop: !this.state.showBackdrop
        }));
    }

    toggleDialog () {
        this.setState( () => ({
            showDialog: !this.state.showDialog
        }));
    }

    setDialogMessage (message) {
        this.setState( () => ({
            dialogMessage: message
        }));
    }

    setDialogTitle (title) {
        this.setState( () => ({
            dialogTitle: title
        }));
    }

    setReturnHome (doReturn) {
        this.setState( () => ({
            returnHome: doReturn
        }));
    }

    render () {
        return (
            <ModalContext.Provider value={this.state}>
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}

export {ModalContext}
export default ModalContextProvider