import React, { useContext, useState, useEffect } from 'react'
import RestService from '../../services/RestService';
import StorageService from '../../services/StorageService';
import {Button, CssBaseline, TextField, Container, FormHelperText, Grid} from '@material-ui/core';
import {InputLabel, Select, MenuItem, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import {ModalContext} from '../../context/ModalContext'
import DateFnsUtils from '@date-io/date-fns'
import {it} from "date-fns/locale"
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import Utils from '../../utils/Utils'
import {Clear} from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import 'fontsource-roboto'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formParagraph: {
        width:'100%',
        marginTop: theme.spacing(4),
    },
    formParagraphFirst: {
        width:'100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    nuova: {
        margin: theme.spacing(3, 2, 2),
    },
    floatingParent: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    floatingChild: {
        display: 'inline-block',
        padding: '10px'
    },
    documento:{
        margin: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: red[50],
        padding: theme.spacing(2),
        '&.loaded':{
            backgroundColor: green[50],
        }
    },
    docButton: {
        marginRight: theme.spacing(2),
    }
  }));

  export default function DettaglioSezione(props) {
    const isAdmin = props.admin
    const modal = useContext(ModalContext)
    const classes = useStyles()
    const [sezioneData, setSezioneData] = useState(null)
    const [dataCf, setDataCf] = useState(null)
    const [provinciaDomicilio, setProvinciaDomicilio] = useState(null)
    const [termineAssicurazione, setTermineAssicurazione] = useState(null)
    const { handleSubmit, control, errors, clearErrors, reset } = useForm({})
    const province = Utils.getProvince()
    const [loaded, setLoaded] = useState(null)
    const [logoImage, setLogoImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [regione, setRegione] = useState(null)
    const regioni = Utils.getRegioni()
    const documentClasses = loaded ? `${classes.documento} loaded` : `${classes.documento}`


    useEffect(() => {
        const updateData = (data, isAdmin) => {
            setDataCf(Utils.parseDate(data.dataCf))
            setTermineAssicurazione(Utils.parseDate(data.termineAssicurazione))
            setProvinciaDomicilio(data.provinciaDomicilio ? data.provinciaDomicilio : '')
            setRegione(data.regione ? data.regione : '')
            setSezioneData(data)
            const isLogoLoaded = data.logo !== null && data.logo !== undefined
            setLoaded(isLogoLoaded)
            if(isAdmin){
                reset({
                    nome: data.nome,
                    cf: data.cf,
                    dataCf: Utils.parseDate(data.dataCf),
                    toponimoDomicilio: data.toponimoDomicilio,
                    indirizzoDomicilio: data.indirizzoDomicilio,
                    civicoDomicilio: data.civicoDomicilio,
                    comuneDomicilio: data.comuneDomicilio,
                    provinciaDomicilio: data.provinciaDomicilio ? data.provinciaDomicilio : '',
                    capDomicilio: data.capDomicilio,
                    cfRappresentante: data.cfRappresentante,
                    nomeRappresentante: data.nomeRappresentante,
                    cognomeRappresentante: data.cognomeRappresentante,
                    quotaIscrizione: Utils.formatCurrency(data.quotaIscrizione),
                    iban: data.iban,
                    intestatarioConto: data.intestatarioConto,
                    agenziaAssicurazione: data.agenziaAssicurazione,
                    polizzaAssicurazione: data.polizzaAssicurazione,
                    termineAssicurazione: Utils.parseDate(data.termineAssicurazione),
                    email: data.email,
                    telefono: data.telefono,
                    regione: data.regione
                })
                clearErrors()
            }
            if (isLogoLoaded) {
                let formData = new FormData()
                formData.append("sezione", data.id)
                StorageService.openSezioneLogo(formData)
                    .then((response) => {
                        setLogoImage(response.data);
                    }).catch(() => {

                    })
            }
        }

        const fetchData = async () => {
            updateData(props.data, isAdmin)
        }
    
        fetchData()
    }, [isAdmin, props.data])

    const deleteFile = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Cancellazione')
        let formData = new FormData()
        formData.append("sezione", sezioneData.id)
    
        StorageService.deleteSezioneLogo(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data) {
                    modal.setDialogMessage('Cancellazione effettuata con successo')
                    if (isAdmin){
                        props.reloadData()
                    } else {
                        sezioneData.logo = null
                        props.refreshData(sezioneData)
                    }
                    setLoaded(null)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Cancellazione del logo fallita')
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Cancellazione del logo fallita')
                modal.toggleDialog()
            });
    }

    const upload = () => {
        modal.toggleBackdrop()
        modal.setDialogTitle('Esito Upload')
        let formData = new FormData()
        formData.append("logo", selectedFile)
        formData.append("sezione", sezioneData.id)
    
        StorageService.uploadSezioneLogo(formData)
            .then((response) => { 
                modal.toggleBackdrop()
                if (response.data) {
                    modal.setDialogMessage('Upload effettuato con successo')
                    if (isAdmin){
                        props.reloadData()
                    } else {
                        props.refreshData(response.data)
                    }
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Upload del logo fallito')
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Upload del logo fallito')
                modal.toggleDialog()
                setSelectedFile(null)
            });

        setSelectedFile(null)
    }

    const fileSelectionHandler = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const nuovaSezione = () => {
        setSezioneData([])  
        reset({
            nome: '',
            cf: '',
            dataCf: null,
            toponimoDomicilio: '',
            indirizzoDomicilio: '',
            civicoDomicilio: '',
            comuneDomicilio: '',
            provinciaDomicilio: '',
            capDomicilio: '',
            cfRappresentante: '',
            nomeRappresentante: '',
            cognomeRappresentante: '',
            quotaIscrizione: '',
            iban: '',
            intestatarioConto: '',
            agenziaAssicurazione: '',
            polizzaAssicurazione: '',
            termineAssicurazione: null,
            email: '',
            telefono: '',
            regione: ''
        })
        setDataCf(null)
        setTermineAssicurazione(null)
        setProvinciaDomicilio('')
        setRegione('')
        clearErrors()
        props.resetSelezionata()
    }

    const submitForm = e => {
        modal.toggleBackdrop()
        const data = new FormData()
        sezioneData.id && data.append('id', sezioneData.id)
        data.append('nome', e.nome)
        e.cf && data.append('cf', e.cf)
        dataCf && data.append('dataCf', Utils.formatDate(dataCf))
        e.toponimoDomicilio && data.append('toponimoDomicilio', e.toponimoDomicilio)
        e.indirizzoDomicilio && data.append('indirizzoDomicilio', e.indirizzoDomicilio)
        e.civicoDomicilio && data.append('civicoDomicilio', e.civicoDomicilio)
        e.comuneDomicilio && data.append('comuneDomicilio', e.comuneDomicilio)
        provinciaDomicilio && data.append('provinciaDomicilio', provinciaDomicilio)
        e.capDomicilio && data.append('capDomicilio', e.capDomicilio)
        e.cfRappresentante && data.append('cfRappresentante', e.cfRappresentante)
        e.nomeRappresentante && data.append('nomeRappresentante', e.nomeRappresentante)
        e.cognomeRappresentante && data.append('cognomeRappresentante', e.cognomeRappresentante)
        e.quotaIscrizione && data.append('quotaIscrizione', Utils.parseCurrency(e.quotaIscrizione))
        e.iban && data.append('iban', e.iban)
        e.intestatarioConto && data.append('intestatarioConto', e.intestatarioConto)
        e.agenziaAssicurazione && data.append('agenziaAssicurazione', e.agenziaAssicurazione)
        e.polizzaAssicurazione && data.append('polizzaAssicurazione', e.polizzaAssicurazione)
        termineAssicurazione && data.append('termineAssicurazione', Utils.formatDate(termineAssicurazione))
        e.email && data.append('email', e.email)
        e.telefono && data.append('telefono', e.telefono)
        regione && data.append('regione', regione)
        RestService.updateSezione(data)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifiche')
                if (response.data) {
                    modal.setDialogMessage('Modifiche effettuate con successo')
                    modal.toggleDialog()
                    if (isAdmin){
                        props.reloadData()
                    } else {
                        props.refreshData(response.data)
                    }
                } else {
                    modal.setDialogMessage(response.data.message)
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
            })
    }

    return(
        sezioneData && 
        <div className={classes.floatingParent}>
            <CssBaseline />
            <div className={`${classes.floatingChild} ${classes.paper}`}>
                <Container component="main" maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitForm)} className={classes.form} >
                        <Grid container spacing={1}>
                            <Typography className={classes.formParagraphFirst}>Dati Sezione</Typography>
                            <Controller
                                name="nome"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={sezioneData.nome || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.nome ? errors.nome.message : null}
                                            label="Nome Sezione *"
                                            id="nome"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('name')
                                            }}
                                            error={errors.nome !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="regione"
                                id="regione"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={regione || ''}
                                render={({onChange, value}) => (
                                    <Grid item sm={12} >
                                        <InputLabel id="regione-label">Regione *</InputLabel>
                                        <Select
                                            labelId="regione-label"
                                            fullWidth
                                            error={errors.regione !== undefined}
                                            onChange={(e) => {
                                                onChange(e)
                                                setRegione(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            {
                                                regioni.map( regione =>
                                                    <MenuItem key={regione.name} value={regione.name}>{regione.name}</MenuItem>
                                                )
                                            } 
                                        </Select>
                                    </Grid>   
                                )}    
                            />
                            {errors.regione && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.regione.message}</FormHelperText>}
                            <Controller
                                name="cf"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                    minLength: 11,
                                    maxLength: 16,
                                }}
                                defaultValue={sezioneData.cf || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.cf ? errors.cf.message : null}
                                            label="Codice Fiscale *"
                                            id="cf"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('cf')
                                            }}
                                            error={errors.cf !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                <Controller
                                    name="dataCf"
                                    control={control}
                                    rules={{
                                        required: isAdmin ? false : "obbligatorio",
                                    }}
                                    defaultValue={dataCf}
                                    render={({value, onChange}) => (
                                        <Grid item sm={6} >
                                            <KeyboardDatePicker
                                                disableFuture
                                                disableToolbar
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="dataCf"
                                                label="Data attribuzione cf *"
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('dataCf')
                                                    setDataCf(e)
                                                }}
                                                invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                maxDateMessage="Data non valida"
                                            />
                                            {errors.dataCf && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.dataCf.message}</FormHelperText>}
                                        </Grid>
                                    )} 
                                />
                            </MuiPickersUtilsProvider>
                            <Typography className={classes.formParagraph}>Dati bancari</Typography>
                            <Controller
                                name="intestatarioConto"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.intestatarioConto || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={12} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.intestatarioConto ? errors.intestatarioConto.message : null}
                                            label="Intestatario Conto *"
                                            id="intestatarioConto"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('intestatarioConto')
                                            }}
                                            error={errors.intestatarioConto !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="quotaIscrizione"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                    pattern: {
                                        value: /^(0|[1-9]\d*)(,([1-9]|([0-9][1-9])))?$/,
                                    }
                                }}
                                defaultValue={Utils.formatCurrency(sezioneData.quotaIscrizione) || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.quotaIscrizione ? errors.quotaIscrizione.message : null}
                                            label="Quota Iscrizione *"
                                            id="quotaIscrizione"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('quotaIscrizione')
                                            }}
                                            error={errors.quotaIscrizione !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="iban"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                    minLength: 27,
                                    maxLength: 27,
                                }}
                                defaultValue={sezioneData.iban || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={8} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.iban ? errors.iban.message : null}
                                            label="IBAN *"
                                            id="iban"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('iban')
                                            }}
                                            error={errors.iban !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Typography className={classes.formParagraph}>Domicilio Fiscale</Typography>
                            <Controller
                                name="toponimoDomicilio"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.toponimoDomicilio || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={3} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.toponimoDomicilio ? errors.toponimoDomicilio.message : null}
                                            label="Toponimo *"
                                            id="toponimoDomicilio"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('toponimoDomicilio')
                                            }}
                                            error={errors.toponimoDomicilio !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )} 
                            />
                            <Controller
                                name="indirizzoDomicilio"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.indirizzoDomicilio || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={7} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.indirizzoDomicilio ? errors.indirizzoDomicilio.message : null}
                                            label="Indirizzo *"
                                            id="indirizzoDomicilio"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('indirizzoDomicilio')
                                            }}
                                            error={errors.indirizzoDomicilio !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )} 
                            />
                            <Controller
                                name="civicoDomicilio"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.civicoDomicilio || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={2} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.civicoDomicilio ? errors.civicoDomicilio.message : null}
                                            label="Civico *"
                                            id="civicoDomicilio"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('civicoDomicilio')
                                            }}
                                            error={errors.civicoDomicilio !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )} 
                            />
                            <Controller
                                name="comuneDomicilio"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.comuneDomicilio || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={5} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.comuneDomicilio ? errors.comuneDomicilio.message : null}
                                            label="Comune *"
                                            id="comuneDomicilio"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('comuneDomicilio')
                                            }}
                                            error={errors.comuneDomicilio !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )} 
                            />
                            <Grid item sm={5} >
                                <Controller
                                    name="provinciaDomicilio"
                                    id="provinciaDomicilio"
                                    control={control}
                                    rules={{
                                        required: isAdmin ? false : "obbligatorio",
                                    }}
                                    defaultValue={provinciaDomicilio || ''}
                                    render={({onChange, value}) => (
                                        <>
                                        <InputLabel id="provincia-label">Provincia *</InputLabel>
                                        <Select
                                            labelId="provincia-label"
                                            fullWidth
                                            error={errors.provinciaDomicilio !== undefined}
                                            onChange={(e) => {
                                                onChange(e)
                                                setProvinciaDomicilio(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            {
                                                province.map( prov =>
                                                    <MenuItem key={prov.abbreviation} value={prov.abbreviation}>{prov.name}</MenuItem>
                                                )
                                            } 
                                        </Select>
                                        </>    
                                    )}    
                                />
                                {errors.provinciaDomicilio && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.provinciaDomicilio.message}</FormHelperText>}
                            </Grid>
                            <Controller
                                name="capDomicilio"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                    minLength: 5,
                                    maxLength: 5,
                                    pattern: {
                                        value: /[0-9]{5}/,
                                    }
                                }}
                                defaultValue={sezioneData.capDomicilio || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={2} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.capDomicilio ? errors.capDomicilio.message : null}
                                            label="CAP *"
                                            id="capDomicilio"
                                            name="capDomicilio"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('capDomicilio')
                                            }}
                                            error={errors.capDomicilio !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )} 
                            />
                            <Typography className={classes.formParagraph}>Contatti</Typography>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "indirizzo email non valido"
                                    }
                                }}
                                defaultValue={sezioneData.email || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.email ? errors.email.message : null}
                                            label="Email *"
                                            id="email"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('email')
                                            }}
                                            error={errors.email !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                            <Controller
                                name="telefono"
                                control={control}
                                rules={{
                                    required: "obbligatorio",
                                }}
                                defaultValue={sezioneData.telefono || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={6} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.telefono ? errors.telefono.message : null}
                                            label="Telefono *"
                                            id="telefono"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('telefono')
                                            }}
                                            error={errors.telefono !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}    
                            />
                            <Typography className={classes.formParagraph}>Dati Rappresentante</Typography>
                            <Controller
                                name="cfRappresentante"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                    minLength: 16,
                                    maxLength: 16,
                                }}
                                defaultValue={sezioneData.cfRappresentante || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.cfRappresentante ? errors.cfRappresentante.message : null}
                                            label="Codice Fiscale *"
                                            id="cfRappresentante"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('cfRappresentante')
                                            }}
                                            error={errors.cfRappresentante !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="nomeRappresentante"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.nomeRappresentante || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.nomeRappresentante ? errors.nomeRappresentante.message : null}
                                            label="Nome *"
                                            id="nomeRappresentante"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('nomeRappresentante')
                                            }}
                                            error={errors.nomeRappresentante !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="cognomeRappresentante"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.cognomeRappresentante || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.cognomeRappresentante ? errors.cognomeRappresentante.message : null}
                                            label="Cognome *"
                                            id="cognomeRappresentante"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('cognomeRappresentante')
                                            }}
                                            error={errors.cognomeRappresentante !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Typography className={classes.formParagraph}>Dati Assicurazione</Typography>
                            <Controller
                                name="agenziaAssicurazione"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.agenziaAssicurazione || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.agenziaAssicurazione ? errors.agenziaAssicurazione.message : null}
                                            label="Agenzia *"
                                            id="agenziaAssicurazione"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('agenziaAssicurazione')
                                            }}
                                            error={errors.agenziaAssicurazione !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <Controller
                                name="polizzaAssicurazione"
                                control={control}
                                rules={{
                                    required: isAdmin ? false : "obbligatorio",
                                }}
                                defaultValue={sezioneData.polizzaAssicurazione || ''}
                                render={({value, onChange}) => (
                                    <Grid item sm={4} >
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.cf ? errors.polizzaAssicurazione.message : null}
                                            label="Numero Polizza *"
                                            id="polizzaAssicurazione"
                                            onChange={(e) => {
                                                onChange(e) 
                                                clearErrors('polizzaAssicurazione')
                                            }}
                                            error={errors.polizzaAssicurazione !== undefined}
                                            value={value || ''}
                                        />
                                    </Grid>
                                )}   
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                                <Controller
                                    name="termineAssicurazione"
                                    control={control}
                                    rules={{
                                        required: isAdmin ? false : "obbligatorio",
                                    }}
                                    defaultValue={termineAssicurazione}
                                    render={({value, onChange}) => (
                                        <Grid item sm={4} >
                                            <KeyboardDatePicker
                                                disableToolbar
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="termineAssicurazione"
                                                label="Data termine *"
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e) 
                                                    clearErrors('termineAssicurazione')
                                                    setTermineAssicurazione(e)
                                                }}
                                                invalidDateMessage="Formato non valido (gg/mm/aaaa)"
                                                maxDateMessage="Data non valida"
                                            />
                                            {errors.termineAssicurazione && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.termineAssicurazione.message}</FormHelperText>}
                                        </Grid>
                                    )} 
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <div style={{textAlign: "right"}}>
                            { isAdmin && sezioneData.id &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.nuova}
                                    onClick={nuovaSezione}
                                >
                                    Nuova
                                </Button>
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {sezioneData.id ? "Aggiorna" : "Inserisci"}
                            </Button>
                        </div>
                    </form> 
                </Container>
            </div>
            <div className={`${classes.floatingChild} ${classes.paper}`}>
                <Container component="main" maxWidth='xs' className={documentClasses}>
                    <Grid container spacing={1}>
                        <Typography className={classes.formParagraphFirst}>Logo Sezione</Typography>
                        <Grid item sm={6} >
                            {loaded && logoImage && <img style={{width:200}} src={URL.createObjectURL(logoImage)} />}
                            {!loaded && 
                                <Clear
                                    htmlColor='red'
                                    fontSize='large'
                                />
                            }
                        </Grid>
                        <Grid item sm={6} >
                            { loaded && 
                                <Button
                                    className={classes.docButton}
                                    variant="outlined"
                                    color="primary"
                                    component="span" 
                                    onClick={deleteFile}   
                                >
                                    Cancella file
                                </Button>
                            }
                            { !loaded &&
                                <label htmlFor={`btn-upload-${props.tipo}`} >   
                                    <input
                                        id={`btn-upload-${props.tipo}`}
                                        name={`btn-upload-${props.tipo}`}
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={fileSelectionHandler} 
                                    />
                                    <Button
                                        className={classes.docButton}
                                        variant="contained"
                                        color="primary"
                                        component="span" >
                                        Seleziona File
                                    </Button>
                                </label>
                            }
                            { !loaded && selectedFile && selectedFile.size > 0 &&
                                <Button
                                    className={classes.docButton}
                                    variant="contained"
                                    color="primary"
                                    component="span" 
                                    onClick={upload}    
                                >
                                    Upload
                                </Button>              
                            }
                            { !loaded && selectedFile && selectedFile.size > 0 && 
                                <>
                                    <br/>
                                    <Typography variant="caption">
                                        {selectedFile.name}
                                    </Typography>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
  }