import React, { useContext, useState } from 'react';
import Login from './Login';
import {UserContext} from '../context/UserContext'
import { Button, Container, CssBaseline, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AuthenticationService from '../services/AuthenticationService';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }));

export default function  Home() {
    const user = useContext(UserContext)
    const classes = useStyles();
    const [showBackdrop, setShowBackdrop] = useState(false)

    const resendConfirmationEmail = () => {
        setShowBackdrop(true)
        AuthenticationService.sendConfirmEmail()
            .then((response) => {
                setShowBackdrop(false)
            }).catch(() => {
                setShowBackdrop(false)
            })
    }

    return (
        <>
        {!user.loggedIn && <Login/>}
        {user.loggedIn && 
            <>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography variant="h5" >
                            Benvenuto {user.name}
                        </Typography>
                        {(!user.enabled) && 
                            <>
                            <Typography style={{marginTop: 20}}>
                                Devi confermare l'indirizzo email e attendere 
                                l'abilitazione da parte di un amministratore per
                                poter compilare l'anagrafica
                            </Typography>
                            <Button onClick={resendConfirmationEmail} variant="contained" color="primary">
                                Reinvia email di conferma
                            </Button>
                            </>
                        } 
                        {(user.enabled === 1) && 
                            <>
                            <Typography style={{marginTop: 20}}>
                                Devi attendere 
                                l'abilitazione da parte di un amministratore per
                                poter compilare l'anagrafica
                            </Typography>
                            </>
                        } 
                    </div>
                </Container>
                <Backdrop className={classes.backdrop} open={showBackdrop} onClick={() => setShowBackdrop(false)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        }
        </>
    )
}