import React, { useState, useContext, useRef } from 'react'
import {Button, Avatar, CssBaseline, TextField, Link , Grid, Typography, Container} from '@material-ui/core';
import {FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText} from '@material-ui/core';
import {LockOutlined, Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import AuthenticationService from '../services/AuthenticationService';
import {UserContext} from '../context/UserContext'
import 'fontsource-roboto';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    password: {
        marginTop: theme.spacing(2)
    }
  }));

export default function Login() {
    const user = useContext(UserContext)
    const classes = useStyles();
    const [hasLoginFailed, setLoginFailed] = useState(false)
    const [showPassword, setShowPassord] = useState(false)
    const loginForm = useRef(null)
    const { handleSubmit, control, errors, reset, clearErrors } = useForm();

    const toggleShowPassword = () => {
        setShowPassord(!showPassword)
    }

    const submitForm = e => {
        const data = new FormData(loginForm.current)
        data.append('password', e.password)
        AuthenticationService
            .executeAuthentication(data)
            .then((response) => {
                if (!response) {
                    throw 'errore comunicazione server'
                }
                AuthenticationService.registerLogin(response, user)
            }).catch(() => {
                setLoginFailed(true)
                reset(
                    {
                      username: '',
                      password: ''
                    },
                    {
                      errors: true,
                      dirtyFields: true
                    }
                );
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                Autenticazione NET.PRO
                </Typography>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form} ref={loginForm} >
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'campo obbligatorio',
                        }}
                        as={
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.username ? errors.username.message : null}
                                id="username"
                                label="Indirizzo email"
                                autoFocus
                                onChange={() => clearErrors('username')}
                                error={errors.username !== undefined}
                            />
                        }
                    />  
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'campo obbligatorio',
                        }}
                        as={
                            <FormControl variant="outlined" fullWidth className={classes.password}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={() => clearErrors('password')}
                                    error={errors.password !== undefined}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                                edge="end"
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                        }    
                    />
                    {errors.password && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>{errors.password.message}</FormHelperText>}
                    {hasLoginFailed && <div style={{color: "red", textAlign: "center"}}>Credenziali errate</div>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Login
                    </Button>
                    <Grid container>
                        <Grid item xs>
                        <Link href="/passwordReset" variant="body2">
                            Password dimenticata?
                        </Link>
                        </Grid>
                        <Grid item>
                        <Link href="/registrazione" variant="body2">
                            Registrazione
                        </Link>
                        </Grid>
                    </Grid>   
                </form>
            </div>
        </Container> 
    )
}