import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import {UserContext} from '../context/UserContext'

class AuthenticatedRoute extends Component {
    static contextType = UserContext
    render() {
        const user = this.context
        if (user.loggedIn) {
            return <Route {...this.props} />
        } else {
            return <Redirect to="/" />
        }

    }
}

export default AuthenticatedRoute