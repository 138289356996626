import React from 'react'
import {CssBaseline, Container, Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Bonifico from './Bonifico'
import {differenceInYears, getYear} from 'date-fns'
import Utils from '../../utils/Utils'
import 'fontsource-roboto'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formParagraphFirst: {
        width:'100%',
        fontWeight: 'bold'
    },
  }));

export default function Bonifici(props) {
    const classes = useStyles()
    const bonificiData = props.data
    const iscrizione = props.iscrizione
    const firstYear = getYear(iscrizione)
    const numYears = differenceInYears(new Date(), new Date(firstYear,0,1)) + 1

    const getBonificiList = (firstYear, numYears) => {
        let bonifici = []
        for (let i = 0; i < numYears; i++) {
            let year = firstYear+i
            const bonifico = 
                            <Grid key={year} item sm={12}>
                                <Bonifico
                                    anno={year}
                                    user={props.user}
                                    data={bonificiData.filter((e) => e.anno === year)[0]}
                                    addRicevuta={props.addRicevuta} 
                                    removeRicevuta={props.removeRicevuta}
                                    refreshData={props.refreshData}
                                    deleteBonifico={props.deleteBonifico}
                                />
                            </Grid>
            bonifici.push(bonifico)
        }
        return bonifici
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container spacing={1}>
                    <Typography className={classes.formParagraphFirst} variant="h6">
                        Dati per la compilazione
                    </Typography>
                    <Typography style={{marginBottom:"10px"}}>
                        (dopo aver premuto il tasto aggiorna comparirà un campo per l’upload della ricevuta del bonifico che poi andrà caricata)
                    </Typography>
                    <Typography className={classes.formParagraphFirst}>
                        Intestatario Conto: <span style={{fontWeight:"normal"}}>{props.user.sezione.intestatarioConto}</span>
                    </Typography>
                    <Typography className={classes.formParagraphFirst}>
                        IBAN: <span style={{fontWeight:"normal"}}>{props.user.sezione.iban}</span>
                    </Typography>
                    <Typography className={classes.formParagraphFirst}>
                        Quota Associativa: <span style={{fontWeight:"normal"}}>{Utils.formatCurrency(props.user.sezione.quotaIscrizione)} €</span>
                    </Typography>
                    <div style={{marginTop:"20px"}}>{ getBonificiList(firstYear, numYears) }</div>
                </Grid>
            </div>
        </Container>
    )
}