import http from "../utils/http-common";

class AuthenticationService {
    executeAuthentication(data) {
        return http.post(`/auth/connessione`, data);
    }

    registerLogin(response, user) {
        user.setLogged(true)
        user.setUsername(response.data.username)
        user.setName(`${response.data.nome} ${response.data.cognome}`)
        user.setLevel(response.data.livello)
        user.setEnabled(response.data.abilitato)
    }

    executeRegistration(data) {
        return http.post(`/auth/registrazione`, data)
    }

    logout(user) {
        http.post(`/auth/disconnessione`)
        this.invalidateSession(user)
    }

    invalidateSession(user) {
        user.setLogged(false)
    }

    confirmEmail(token){
        return http.post(`/auth/confermaEmail`, {token: token});
    }

    sendConfirmEmail(){
        return http.post(`/auth/inviaEmailConferma`);
    }

    resetPassword(username){
        return http.post(`/auth/resetPassword`, {username: username});
    }

    changePassword(token, user, password){
        return http.post(`/auth/changePassword`, {token: token, user: user, password: password});
    }
}

export default new AuthenticationService()
