import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './Header';
import User from './user/User';
import Home from './Home';
import SignIn from './SignIn';
import Admin from './admin/Admin';
import Sezione from './sezione/Sezione';
import EmailConfirmation from './EmailConfirmation';
import AuthenticatedRoute from './AuthenticatedRoute'
import {UserContext} from '../context/UserContext'
import PasswordReset from './PasswordReset';
import Modal from './Modal';


export default function Root(props) {
    const user = useContext(UserContext);

    return (
        <Router>
            <Switch>
                {user.loggedIn && <Route component={Header} />}  
            </Switch>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/confermaEmail/:token" exact component={EmailConfirmation} />
                <AuthenticatedRoute path="/user" exact component={User} />
                <AuthenticatedRoute path="/sezione" render={(props) => (<Sezione {...props} admin={false} />)} />
                <AuthenticatedRoute path="/admin" exact component={Admin} />
                <Route path="/registrazione" exact component={SignIn} />
                <Route path="/passwordReset/:token" render={(props) => (<PasswordReset {...props} hasToken={true} />)} />
                <Route path="/passwordReset" render={(props) => (<PasswordReset {...props} hasToken={false} />)} />    
            </Switch>
            <Modal/>
        </Router>
    )
}