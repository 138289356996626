import React, { useState, useContext, useEffect } from 'react'
import RestService from '../../services/RestService'
import {Container, Typography, Button, IconButton} from '@material-ui/core'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import 'fontsource-roboto';
import {UserContext} from '../../context/UserContext'
import User from '../user/User'
import Utils from '../../utils/Utils'
import * as Constants from '../../utils/Constants'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import {makeStyles} from '@material-ui/core/styles';
import {ModalContext} from '../../context/ModalContext'
import DocService from '../../services/DocService';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    back: {
        margin: theme.spacing(3, 2, 2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function ManageUsers() {
    const modal = useContext(ModalContext)
    const user = useContext(UserContext)
    const [reloadTable, setReloadTable] = useState(false)
    const [allUsersData, setAllUsersData] = useState(null)
    const [selectedUser, setSelectedUser]  = useState(null)
    const [selectedName, setSelectedName]  = useState(null)
    const [sezioni, setSezioni] = useState([])
    const [gridRef, setGridRef] = useState(null)
    const classes = useStyles()

    const gridStyle = { minHeight: 300 }

    const livelli = [
        {id: Constants.USER, label: Utils.getPermessi(Constants.USER)},
        {id: Constants.SECTION_LEADER, label: Utils.getPermessi(Constants.SECTION_LEADER)},
        {id: Constants.ADMIN, label: Utils.getPermessi(Constants.ADMIN)}
    ]

    const abilitazioni = [
        {id: 0, label: Utils.getAbilitazione(0)},
        {id: 1, label: Utils.getAbilitazione(1)},
        {id: 2, label: Utils.getAbilitazione(2)}
    ]

    const filterValue = [
        { name: 'username', operator: 'startsWith', type: 'string'},
        { name: 'nome', operator: 'startsWith', type: 'string'},
        { name: 'cf', operator: 'startsWith', type: 'string' },
        { name: 'sezione', operator: 'eq', type: 'number' },
        { name: 'livello', operator: 'eq', type: 'number' },
        { name: 'abilitato', operator: 'eq', type: 'number' },
    ]

    const columns = [
        { name: 'username', header: 'Username' },
        { name: 'nome', header: 'Nome',
            render: ({ data }) => {
                return data.nome + ' ' + data.cognome
            }
        },
        { name: 'cf', header: 'CF' },
        { name: 'sezione', header: 'Sezione',
            render: ({ data }) => {
                return data.sezione.nome
            },
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Sezioni',
                dataSource: sezioni
            },
        },
        { name: 'livello', header: 'Livello', 
            render: ({ value }) => {
                return Utils.getPermessi(value)
            },
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Livelli',
                dataSource: livelli
            },
        },
        { name: 'abilitato', header: 'Abilitazione', 
            render: ({ value }) => { 
                return Utils.getAbilitazione(value) 
            },
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Abilitazioni',
                dataSource: abilitazioni
            },
        }
    ]

    const onSelectionChange = (e)  => {
        setSelectedUser(e.selected)
        setSelectedName(e.data.nome + " " + e.data.cognome)
        if (gridRef) {
            console.log(Object.keys(gridRef.current.dataIndexMap))
        }
      }

    const refreshTable = () => {
        setReloadTable(reloadTable ? false : true)
    }

    const estraiSelezione = () => {
        const users = Object.keys(gridRef.current.dataIndexMap)
        modal.toggleBackdrop()
        DocService.getEstrazioneUtenti(users)
            .then((response) => {
                const blob = new Blob(
                    [response.data], 
                    {type: 'text/csv;charset=utf-8'}
                );
                Utils.downloadBlob(blob, 'estrazione-utenti.csv')
                modal.toggleBackdrop()
            }).catch((e) => {
                modal.toggleBackdrop()
                console.log(e)
            })
    }

    const estraiTutti = () => {
        const users = []
        modal.toggleBackdrop()
        DocService.getEstrazioneUtenti(users)
            .then((response) => {
                const blob = new Blob(
                    [response.data], 
                    {type: 'text/csv;charset=utf-8'}
                );
                Utils.downloadBlob(blob, 'estrazione-utenti.csv')
                modal.toggleBackdrop()
            }).catch((e) => {
                modal.toggleBackdrop()
                console.log(e)
            })
    }

    const estraiUtente = () => {
        const users = [selectedUser]
        modal.toggleBackdrop()
        DocService.getEstrazioneUtenti(users)
            .then((response) => {
                const blob = new Blob(
                    [response.data], 
                    {type: 'text/csv;charset=utf-8'}
                );
                Utils.downloadBlob(blob, 'estrazione-utenti.csv')
                modal.toggleBackdrop()
            }).catch((e) => {
                modal.toggleBackdrop()
                console.log(e)
            })
    }

    useEffect(() => {
        const fetchData = async () => {
          RestService.getAllUsers()
            .then((response) => {
                setAllUsersData(response.data)
            }).catch((e) => {
                console.log(e)
            })
            
        }

        const fetchSezioni = async () => {
            RestService.getSezioniDropdown()
              .then((response) => {
                  const sezioniForFilter = []
                  response.data.map( sez =>
                    sezioniForFilter.push({id: sez.id, label: sez.nome})
                  )
                  setSezioni(sezioniForFilter)
              }).catch((e) => {
                  console.log(e)
              }) 
        }

        fetchData()
        fetchSezioni()
    }, [user.username, reloadTable]);

    return (
        <Container component="main" maxWidth="lg">
            <div>
                {allUsersData && 
                    <ReactDataGrid
                        idProperty="username"
                        columns={columns}
                        dataSource={allUsersData}
                        enableSelection={true}
                        onSelectionChange={onSelectionChange}
                        defaultFilterValue={filterValue}
                        style={gridStyle}
                        pagination
                        onReady={setGridRef}
                    />
                }
            </div>
            <div style={{textAlign:"right", paddingTop:"20px"}}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.back}
                    onClick={estraiSelezione}
                >
                    Estrai selezione attuale
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={estraiTutti}
                >
                    Estrai tutti gli utenti
                </Button>
            </div>
            <div style={{textAlign:"center", paddingTop:"20px"}}>
                <Typography variant="h5">
                    {selectedName}
                    {selectedUser && <IconButton
                                        aria-label="export user"
                                        onClick={estraiUtente}
                                        edge="end"
                                        color="primary"
                                    >
                                        <SaveAltIcon />
                                    </IconButton>
                    }
                </Typography>
            </div>
            <div>
                {selectedUser && <User usernameProvided={selectedUser} refreshTable={refreshTable}/> }
            </div>
        </Container>
    )
}