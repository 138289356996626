import React, { useRef, useState, useContext } from 'react'
import AuthenticationService from '../services/AuthenticationService';
import {Button, CssBaseline, TextField, Typography, Container, FormHelperText} from '@material-ui/core';
import {FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import 'fontsource-roboto';
import { useHistory } from 'react-router-dom';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { useParams } from 'react-router-dom'
import {ModalContext} from '../context/ModalContext'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
      },
      back: {
          margin: theme.spacing(3, 2, 2),
      },
  }));

export default function PasswordReset(props) {
    const modal = useContext(ModalContext)
    const hasToken = props.hasToken
    const { token } = useParams();
    const classes = useStyles();
    const resetForm = useRef(null)
    const { handleSubmit, control, errors, reset, clearErrors } = useForm();
    const [password, setPassword] = useState('')
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const [showPassword, setShowPassord] = useState(false)
    const toggleShowPassword = () => {
        setShowPassord(!showPassword)
    }

    const submitForm = e => {
        modal.toggleBackdrop()
        if (hasToken) {
            AuthenticationService.changePassword(token, null, password)
            .then((response) => {
                modal.toggleBackdrop()
                modal.setDialogTitle('Esito Modifica Password')
                if (response.data.esito === 'ok') {
                    modal.setDialogMessage('Password modificata con successo')
                    modal.setReturnHome(true)
                    modal.toggleDialog()
                } else {
                    modal.setDialogMessage('Collegamento di reset della password errato/scaduto')
                    modal.toggleDialog()
                }
            }).catch(() => {
                modal.setDialogMessage('Errore nella comunicazione col server')
                modal.toggleDialog()
                reset(
                    {
                      username: '',
                      password: ''
                    },
                    {
                      errors: true,
                      dirtyFields: true
                    }
                );
            })
        } else {
            const data = new FormData(resetForm.current)
            AuthenticationService.resetPassword(data.get('username'))
                .then((response) => {
                    modal.toggleBackdrop()
                    modal.setDialogTitle('Richiesta Reset Password')
                    modal.setDialogMessage('Ti abbiamo inviato una email, usa il collegamento contenuto per completare la procedura di reset della password')
                    modal.setReturnHome(true)
                    modal.toggleDialog()
                }).catch(() => {
                    modal.setDialogMessage('Errore nella comunicazione col server')
                    modal.toggleBackdrop()
                    modal.toggleDialog()
                    reset(
                        {
                          username: ''
                        },
                        {
                          errors: true,
                          dirtyFields: true
                        }
                    );
                })
        }
    }

    const history = useHistory();
    const handleReturnClick = () => history.push('/')

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                {!hasToken ? 'Reset della password' : 'Nuova password'}
                </Typography>
                <form onSubmit={handleSubmit(submitForm)} className={classes.form} ref={resetForm} >
                    {!hasToken && <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'campo obbligatorio',
                        }}
                        as={
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText={errors.username ? errors.username.message : null}
                                id="username"
                                label="Username (indirizzo email)"
                                autoFocus
                                onChange={() => clearErrors('username')}
                                error={errors.username !== undefined}
                            />
                        }
                    />
                    }
                    {hasToken &&
                        <>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            id="password"
                            rules={{
                                required: true,
                                minLength: 8
                            }}
                            render={({onChange}) => (
                                <>
                                <FormControl variant="outlined" fullWidth className={classes.password}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => {
                                            onChange(e)
                                            handlePasswordChange(e)
                                        }}
                                        error={errors.password !== undefined}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                                {errors.password && <FormHelperText style={{color:"#f44336", marginLeft:"16px"}}>campo obbligatorio di almeno 8 caratteri</FormHelperText>}
                                <PasswordStrengthMeter password={password} />
                                </>
                            )}
                        />
                        </>
                    }
                    <div style={{textAlign: "right"}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.back}
                            onClick={handleReturnClick}
                        >
                            Indietro
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Invia
                        </Button>
                    </div>  
                </form>
            </div>
        </Container>
    )
}