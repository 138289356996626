import React, { useState, useContext, useEffect } from 'react'
import RestService from '../../services/RestService';
import {Button, CssBaseline, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Paper, Backdrop, CircularProgress, Dialog, DialogContentText, DialogContent, DialogActions, DialogTitle} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import 'fontsource-roboto';
import {UserContext} from '../../context/UserContext'
import Utils from '../../utils/Utils'
import * as Constants from '../../utils/Constants'

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    table: {
      minWidth: 650,
    },
    tablecontainer: {
      marginTop: theme.spacing(4),
    }
  }));

export default function Users(props) {
    const user = useContext(UserContext)
    const classes = useStyles()
    const [reloadTable, setReloadTable] = useState(false)
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [modificaOk, setModificaOk] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('')
    const [allUsersData, setAllUsersData] = useState(null)
    const handleCloseDialog = () => {
        if (modificaOk) {
            setModificaOk(false)
            setReloadTable(reloadTable ? false : true)
        }
        setShowBackdrop(false);
        setOpenDialog(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = new FormData()
            data.append('id', props.sezione)
            RestService.getSezioneUsers(data)
                .then((response) => {
                    setAllUsersData(response.data.filter((e) => e.username !== user.username))
                }).catch((e) => {
                    console.log(e)
                }) 
        }

        fetchData()
      }, [user.username, reloadTable, props.sezione]);

    const updateAbilitazione = (username, abilitazione) => {
        setShowBackdrop(true)
        const data = new FormData()
        data.append('username', username)
        data.append('abilitato', abilitazione)
        RestService.updateUserEnabled(data)
            .then((response) => {
                if (response.data.message === 'ok') {
                    setDialogMessage('Modifiche effettuate con successo')
                    setOpenDialog(true)
                    setModificaOk(true)
                } else {
                    setDialogMessage(response.data.message)
                    setOpenDialog(true)
                }
            }).catch(() => {
                setDialogMessage('Errore nella comunicazione col server')
                setShowBackdrop(false);
            })
    }

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography>Abilitazione utenti</Typography>
                {allUsersData && 
                    <TableContainer className={classes.tablecontainer} component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Username</StyledTableCell>
                                    <StyledTableCell>Nome</StyledTableCell>
                                    <StyledTableCell>Cognome</StyledTableCell>
                                    <StyledTableCell>Codice Fiscale</StyledTableCell>
                                    <StyledTableCell>Permessi</StyledTableCell>
                                    <StyledTableCell>Abilitato</StyledTableCell>
                                    <StyledTableCell>Azioni</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {allUsersData.map((row) => (
                                <TableRow key={row.username}>
                                    <TableCell component="th" scope="row">
                                        {row.username}
                                    </TableCell>
                                    <TableCell>{row.nome}</TableCell>
                                    <TableCell>{row.cognome}</TableCell>
                                    <TableCell>{row.cf}</TableCell>
                                    <TableCell>{Utils.getPermessi(row.livello)}</TableCell>
                                    <TableCell>{Utils.getAbilitazione(row.abilitato)}</TableCell>
                                    <TableCell>
                                        {row.livello === Constants.USER && row.abilitato === 1 && <Button variant="contained" color="primary" onClick={() => updateAbilitazione(row.username, 2)}>Abilita</Button>}
                                        {row.livello === Constants.USER && row.abilitato === 2 && <Button variant="contained" color="primary" onClick={() => updateAbilitazione(row.username, 1)}>Disabilita</Button>}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop} onClick={() => setShowBackdrop(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle id="alert-dialog-title">{"Esito Modifiche"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}